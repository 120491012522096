import { APINotreNotaire } from '../helpers/AxiosAPI'

export const horrairesService = {
    createHorraire,
    getHorraires,
    deleteHorraire,
}

function createHorraire(horraire, id) {
    return APINotreNotaire.post(`post_horaire/${id}`, horraire)
}

function getHorraires(id) {
    return APINotreNotaire.get(`get_horaires/${id}`)
}

function deleteHorraire(id) {
    return APINotreNotaire.delete(`destroy_admin/${id}`)
}
