import { APINotreNotaire } from '../helpers/AxiosAPI'

export const footerServices = {
    updateFooter,
    getFooter,
}

function updateFooter(data, footer_id) {
    return APINotreNotaire.put(`footers/${footer_id}`, data)
}

function getFooter(id) {
    return APINotreNotaire.get(`footers/${id}`)
}
