import { notairepremiumConstants } from '../constants/notairespremium.constants'
import { notairepremiumServices } from '../services/notairespremium.services.js'
import { res } from '../helpers/dispatch'

export const notairespremiumActions = {
    getNotairesPremium,
    filtreNotairesPremium,
    getNotairesPremiumNoWebsite,
}

function getNotairesPremium(pages) {
    return (dispatch) => {
        dispatch(res.loading(notairepremiumConstants.NOTAIRES_PREMIUM_LOADING))
        notairepremiumServices
            .getNotairesPremium(pages)
            .then((response) => {
                let notairesPremium = {}
                notairesPremium.data = response.data
                notairesPremium.pages = response.headers['total-pages']
                dispatch(
                    res.success(
                        notairepremiumConstants.GETNOTAIRES_PREMIUM_SUCCESS,
                        notairesPremium
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        notairepremiumConstants.GETNOTAIRES_PREMIUM_FAILURE,
                        error
                    )
                )
            })
    }
}

function getNotairesPremiumNoWebsite(pages) {
    return (dispatch) => {
        dispatch(res.loading(notairepremiumConstants.NOTAIRES_PREMIUM_LOADING))
        notairepremiumServices
            .getNotairesPremiumNoWebsite(pages)
            .then((response) => {
                let notairesPremium = {}
                notairesPremium.data = response.data
                notairesPremium.pages = response.headers['total-pages']
                dispatch(
                    res.success(
                        notairepremiumConstants.GETNOTAIRES_PREMIUM_SUCCESS,
                        notairesPremium
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        notairepremiumConstants.GETNOTAIRES_PREMIUM_FAILURE,
                        error
                    )
                )
            })
    }
}

function filtreNotairesPremium(filtres, page) {
    return (dispatch) => {
        dispatch(res.loading(notairepremiumConstants.NOTAIRES_PREMIUM_LOADING))
        notairepremiumServices
            .filtreNotairesPremium(filtres, page)
            .then((response) => {
                let notairesPremium = {}
                notairesPremium.data = response.data
                notairesPremium.pages = response.headers['total-pages']
                dispatch(
                    res.success(
                        notairepremiumConstants.FILTRENOTAIRES_PREMIUM_SUCCESS,
                        notairesPremium
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        notairepremiumConstants.GETNOTAIRES_PREMIUM_FAILURE,
                        error
                    )
                )
            })
    }
}
