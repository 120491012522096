import { userConstants } from '../constants/user.constants'
import { userService } from '../services/user.service.js'
import { alertActions } from './alert.actions.js'
import { history } from '../helpers/history'

export const userActions = {
    login,
    logout,
}

function login(email, password) {
    return (dispatch) => {
        userService.login(email, password).then(
            () => {
                history.push('/statistique')
            },
            (error) => {
                dispatch(alertActions.error(error.toString()))
            }
        )
    }
}

function logout() {
    userService.logout()
    history.push('/login')
    return { type: userConstants.LOGOUT }
}
