import { chartsConstants } from '../constants/charts.constants.js'
import { chartsServices } from '../services/charts.services.js'
import { res } from '../helpers/dispatch'

export const chartsActions = {
    getLeadsZone,
    getLeadsStatuts,
    getLeadsMonth,
    getTurnover,
    getDemandeDevis,
    getDemandeDevisZone,
    getUsersConnection,
    getQuotationConversionRate,
    getQuotationAccept,
    getDepartementsStat,
    getDepartementStat,
    getNotairePremiumMap,
}

function getLeadsZone() {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETLEADSZONE_LOADING))
        chartsServices
            .getLeadsZone()
            .then((response) => {
                let leadsZone = response.data
                dispatch(
                    res.success(chartsConstants.GETLEADSZONE_SUCCESS, leadsZone)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(chartsConstants.GETLEADSZONE_FAILURE, error)
                )
            })
    }
}

function getLeadsMonth() {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETLEADSMONTH_LOADING))
        chartsServices
            .getLeadsMonth()
            .then((response) => {
                let leadsMonth = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETLEADSMONTH_SUCCESS,
                        leadsMonth
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(chartsConstants.GETLEADSMONTH_FAILURE, error)
                )
            })
    }
}

function getLeadsStatuts() {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETLEADSTATUT_LOADING))
        chartsServices
            .getLeadsStatuts()
            .then((response) => {
                let leadsStatuts = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETLEADSTATUT_SUCCESS,
                        leadsStatuts
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(chartsConstants.GETLEADSTATUT_FAILURE, error)
                )
            })
    }
}

function getTurnover() {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETTURNOVER_LOADING))
        chartsServices
            .getTurnover()
            .then((response) => {
                let turnover = response.data
                dispatch(
                    res.success(chartsConstants.GETTURNOVER_SUCCESS, turnover)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(chartsConstants.GETTURNOVER_FAILURE, error)
                )
            })
    }
}

function getDemandeDevis() {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETDEMANDEDEVIS_LOADING))
        chartsServices
            .getDemandeDevis()
            .then((response) => {
                let demandeDevis = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETDEMANDEDEVIS_SUCCESS,
                        demandeDevis
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(chartsConstants.GETDEMANDEDEVIS_FAILURE, error)
                )
            })
    }
}

function getDemandeDevisZone() {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETDEMANDEDEVISZONES_LOADING))
        chartsServices
            .getDemandeDevisZone()
            .then((response) => {
                let demandeDevis = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETDEMANDEDEVISZONES_SUCCESS,
                        demandeDevis
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        chartsConstants.GETDEMANDEDEVISZONES_FAILURE,
                        error
                    )
                )
            })
    }
}

function getUsersConnection(jours) {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETUSERCONNECTIONS_LOADING))
        chartsServices
            .getUsersConnection(jours)
            .then((response) => {
                let usersConnection = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETUSERCONNECTIONS_SUCCESS,
                        usersConnection
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        chartsConstants.GETUSERCONNECTIONS_FAILURE,
                        error
                    )
                )
            })
    }
}

function getQuotationConversionRate() {
    return (dispatch) => {
        dispatch(
            res.loading(chartsConstants.GETQUOTATIONCONVERSIONRATE_LOADING)
        )
        chartsServices
            .getQuotationConversionRate()
            .then((response) => {
                let quotationConversionRate = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETQUOTATIONCONVERSIONRATE_SUCCESS,
                        quotationConversionRate
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        chartsConstants.GETQUOTATIONCONVERSIONRATE_FAILURE,
                        error
                    )
                )
            })
    }
}

function getQuotationAccept() {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETQUOTATIONACCEPT_LOADING))
        chartsServices
            .getQuotationAccept()
            .then((response) => {
                let quotationAccept = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETQUOTATIONACCEPT_SUCCESS,
                        quotationAccept
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        chartsConstants.GETQUOTATIONACCEPT_FAILURE,
                        error
                    )
                )
            })
    }
}

function getDepartementsStat() {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETDEPARTEMENTSSTAT_LOADING))
        chartsServices
            .getDepartementsStat()
            .then((response) => {
                let departementsStat = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETDEPARTEMENTSSTAT_SUCCESS,
                        departementsStat
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        chartsConstants.GETDEPARTEMENTSSTAT_FAILURE,
                        error
                    )
                )
            })
    }
}

function getDepartementStat(id) {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETDEPARTEMENTSTAT_LOADING))
        chartsServices
            .getDepartementStat(id)
            .then((response) => {
                let departementStat = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETDEPARTEMENTSTAT_SUCCESS,
                        departementStat
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        chartsConstants.GETDEPARTEMENTSTAT_FAILURE,
                        error
                    )
                )
            })
    }
}

function getNotairePremiumMap() {
    return (dispatch) => {
        dispatch(res.loading(chartsConstants.GETNOTAIREMAP_LOADING))
        chartsServices
            .getNotairePremiumMap()
            .then((response) => {
                let NotaireMap = response.data
                dispatch(
                    res.success(
                        chartsConstants.GETNOTAIREMAP_SUCCESS,
                        NotaireMap
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(chartsConstants.GETNOTAIREMAP_FAILURE, error)
                )
            })
    }
}
