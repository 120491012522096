import { APINotreNotaire } from '../helpers/AxiosAPI'

export const homeServices = {
    updateHome,
    getHome,
}

function updateHome(home, home_id) {
    let formData = new FormData()
    for (const property in home) {
        if (home[property] != null) {
            formData.append(property, home[property])
        }
    }
    return APINotreNotaire.put(`home_pages/${home_id}`, formData)
}

function getHome(id) {
    return APINotreNotaire.get(`home_pages/${id}`)
}
