import { homeConstants } from '../../constants/home.constants'
import { homeServices } from '../../services/home.services.js'
import { res } from '../../helpers/dispatch'

export const homeActions = {
    getHome,
    updateHome,
}

function getHome(id) {
    return (dispatch) => {
        dispatch(res.loading(homeConstants.HOME_LOADING))
        homeServices
            .getHome(id)
            .then((response) => {
                let home = response.data
                dispatch(res.success(homeConstants.GETHOME_SUCCESS, home))
            })
            .catch((error) => {
                dispatch(res.failure(homeConstants.GETHOME_FAILURE, error))
            })
    }
}

function updateHome(body, home_id) {
    return (dispatch) => {
        dispatch(res.loading(homeConstants.HOME_LOADING))
        homeServices
            .updateHome(body, home_id)
            .then((response) => {
                let home = response.data
                dispatch(res.success(homeConstants.PUTHOME_SUCCESS, home))
            })
            .catch((error) => {
                dispatch(res.failure(homeConstants.PUTHOME_FAILURE, error))
            })
    }
}
