import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { chartsActions } from '../../../actions/charts.actions.js'
import { Bar } from 'react-chartjs-2'
import Loader from '../../../components/Loader/Loader'

class LeadsMonth extends Component {
    componentDidMount() {
        if (this.props.leadsMonth.leadsMonth.length === 0) {
            this.props.getLeadsMonth()
        }
    }

    render() {
        const { leadsMonth, loading } = this.props.leadsMonth
        const label = []
        const dataset = []
        leadsMonth.map(
            (value) => label.push(value[0]) && dataset.push(value[1])
        )
        const data = {
            labels: label,
            datasets: [
                {
                    label: 'Nombre de leads',
                    backgroundColor: '#f5b9b9',
                    borderColor: '#f5b9b9',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f5b9b9',
                    hoverBorderColor: '#f5b9b9',
                    data: dataset,
                },
            ],
        }
        return loading ? (
            <Loader />
        ) : (
            <div className="containerStats">
                <h3>Nombre de leads par mois </h3>
                <Bar data={data} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        leadsMonth: state.leadsMonth,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getLeadsMonth: chartsActions.getLeadsMonth },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsMonth)
