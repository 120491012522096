import React, { Component } from 'react'
import LeadTable from './LeadTable/LeadTable'
import { LeadsHeader } from './LeadTable/LeadsHeader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getLeads } from '../../actions/leads.actions.js'
import ReactPaginate from 'react-paginate'

class Leads extends Component {
    constructor(props) {
        super(props)

        this.state = {
            current_page: 1,
        }
        this.loadPage = this.loadPage.bind(this)
    }

    componentDidMount() {
        this.props.getLeads(this.state.current_page)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.current_page !== prevState.current_page) {
            this.props.getLeads(this.state.current_page)
        }
    }

    loadPage(page) {
        let newPage = page.selected + 1
        this.setState({ current_page: newPage }, function () {})
    }

    render() {
        if (!this.props.leads) {
            return <p>Loading...</p>
        }
        return (
            <div className="containerAdmin">
                <LeadsHeader />
                <hr />
                <LeadTable data={this.props.leads} />

                <ReactPaginate
                    previousLabel={'Précendent'}
                    nextLabel={'Suivant'}
                    pageCount={parseInt(
                        JSON.parse(localStorage.getItem('pagination_leads'))
                    )}
                    onPageChange={this.loadPage}
                    containerClassName="pagination paginate"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="page-item active"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        leads: state.leads,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getLeads }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Leads)
