import React, { Component } from 'react'
import axios from 'axios'

class InputDomainField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dispo: null,
        }
    }

    apiDomainr() {
        axios
            .get(
                `https://domainr.p.rapidapi.com/v2/status?domain=${this.props.input.value}`,
                {
                    headers: {
                        'x-rapidapi-host': 'domainr.p.rapidapi.com',
                        'x-rapidapi-key': process.env.DOMAINR,
                    },
                }
            )
            .then((res) => {
                if (
                    res.data.status[0]['status'] === 'undelegated inactive' ||
                    res.data.status[0]['status'] === 'undelegated' ||
                    res.data.status[0]['status'] === 'inactive'
                ) {
                    this.setState({ dispo: true })
                } else {
                    this.setState({ dispo: false })
                    this.props.input.onChange('')
                }
            })
    }

    render() {
        const { value, onChange } = this.props.input
        return (
            <div className="row justify-content-md-center">
                <input
                    type="text"
                    value={value}
                    onChange={onChange}
                    className="col-5"
                />
                <button
                    type="button"
                    className="btn btn-primary col-2"
                    onClick={() => this.apiDomainr()}
                >
                    Vérifier la disponibilité
                </button>
                <p className="col-12 text-center" style={{ fontSize: '30px' }}>
                    {this.state.dispo === null
                        ? null
                        : this.state.dispo
                        ? 'Disponible ✅'
                        : 'Non disponible ❌'}
                </p>
            </div>
        )
    }
}

export default InputDomainField
