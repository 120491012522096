import React, { Component } from 'react'

class CheckBoxField extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { name, value, onChange } = this.props.input

        return (
            <label className="switch">
                <input
                    className="ml-5 mr-5"
                    type="checkbox"
                    name={name}
                    onChange={onChange}
                    checked={value === '' ? false : value}
                />
                <span className="slider round"></span>
            </label>
        )
    }
}

export default CheckBoxField
