import React, { Component } from 'react'
import AlgoliaSearchNotaire from './AlgoliaSearch/AlgoliaSearchNotaire'

class Research extends Component {
    render() {
        return (
            <div className="containerAdmin">
                <div className="header">
                    <h2>Recherche d'un notaire</h2>
                </div>
                <hr />
                <AlgoliaSearchNotaire />
            </div>
        )
    }
}

export default Research
