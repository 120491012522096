import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reduxPromise from 'redux-promise'
import logger from 'redux-logger'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from './helpers/privateRoute'

//Components
import App from './App'
import WebSites from './containers/NotairesWebsite/WebSites'
import WebsiteAdd from './containers/NotairesWebsite/Add/WebsiteAdd'
import WebsiteAddNotaire from './containers/NotairesWebsite/Add/WebsiteAddNotaire'
import Leads from './containers/Leads/Leads'
import Lead from './containers/Leads/Lead'
import Statistiques from './containers/Statistiques/Statistiques'
import Users from './containers/NotairesPremium/Users'
import Research from './containers/Research/Research'
import FicheShow from './containers/Notaire/Notaire'
import UserShow from './containers/NotairePremium/NotairePremium'
import WebSite from './containers/NotairesWebsite/Update/WebSite'

//Style
import '../assets/stylesheets/application.scss'

import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { history } from './helpers/history'
import { reducers } from './reducers/store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Contact from './containers/Contact/Contact'
import Departement from './containers/Statistiques/Departements/Departement/Departement'

library.add(fas)

const loggerMiddleware = createLogger()
const middlewares = applyMiddleware(
    reduxPromise,
    logger,
    thunkMiddleware,
    loggerMiddleware
)

// render an instance of the component in the DOM
ReactDOM.render(
    <Provider store={createStore(reducers, {}, middlewares)}>
        <Router history={history}>
            <Switch>
                <Route path="/" exact>
                    <Redirect to="/statistique" />
                </Route>
                <PrivateRoute
                    path="/statistique"
                    exact
                    component={Statistiques}
                />
                <PrivateRoute path="/websites" exact component={WebSites} />
                <PrivateRoute
                    path="/websites/add/:id"
                    exact
                    component={WebsiteAddNotaire}
                />
                <PrivateRoute
                    path="/websites/edit/:id"
                    exact
                    component={WebSite}
                />
                <PrivateRoute
                    path="/websites/add"
                    exact
                    component={WebsiteAdd}
                />
                <PrivateRoute
                    path="/statistique/departement/:id"
                    exact
                    component={Departement}
                />
                <PrivateRoute path="/users" exact component={Users} />
                <PrivateRoute path="/users/:id" exact component={UserShow} />
                <PrivateRoute path="/research" exact component={Research} />
                <PrivateRoute path="/notaire/:id" exact component={FicheShow} />
                <PrivateRoute path="/leads" exact component={Leads} />
                <PrivateRoute path="/lead/:id" exact component={Lead} />
                <PrivateRoute path="/contact" exact component={Contact} />
                <Route path="/login" exact component={App} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
)
