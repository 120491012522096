import { leadsConstants } from '../constants/leads.constants'
import { leadsServices } from '../services/leads.services.js'

export function getLeads(page) {
    const promise = leadsServices.getLeads(page)
    return {
        type: leadsConstants.GETLEADS_REQUEST,
        payload: promise,
    }
}

export function getLead(id) {
    return (dispatch) => {
        leadsServices.getLead(id).then(
            (lead) => {
                dispatch(success(lead))
            },
            (error) => {
                dispatch(failure(error.toString()))
            }
        )
    }
    function success(lead) {
        return { type: leadsConstants.GETLEAD_REQUEST, payload: lead }
    }
    function failure(error) {
        return { type: leadsConstants.LEADS_FAILURE, error }
    }
}
