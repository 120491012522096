import React, {Component} from 'react';
import InfoLead from './InfoLead/InfoLead';


class LeadShow extends Component{
  render(){
      return(
        <div className="row">
          <div className="col-12 blocLead">
            <InfoLead lead={this.props.lead}/>
          </div>
        </div>
      )
  }
}
export default LeadShow;
