import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { notairePremiumActions } from '../../../actions/notairePremium.actions'
import { Pie } from 'react-chartjs-2'
import Loader from '../../../components/Loader/Loader'

class NotairePremiumLeadsStats extends Component {
    componentDidMount() {
        if (this.props.notairePremium.notairePremium !== null) {
            if (this.props.userProfilStats.userProfilStats.length === 0)
                this.props.getUserProfilStat(
                    this.props.notairePremium.notairePremium.user_id
                )
        }
    }

    render() {
        const { loading, userProfilStats } = this.props.userProfilStats
        const label = []
        const dataset = []
        userProfilStats.map(
            (value) => label.push(value[0]) && dataset.push(value[1])
        )
        const data = {
            labels: label,
            datasets: [
                {
                    data: dataset,
                    backgroundColor: [
                        '#19D891',
                        '#FF8C8C',
                        '#8A8CFF',
                        '#66adff',
                    ],
                    hoverBackgroundColor: [
                        '#19D891',
                        '#FF8C8C',
                        '#8A8CFF',
                        '#66adff',
                    ],
                },
            ],
        }
        return loading ? (
            <Loader />
        ) : (
            <Fragment>
                <h4>
                    <u>Situation des leads:</u>
                </h4>
                <div className="bloc_user_notes">
                    <Pie data={data} />
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        userProfilStats: state.userProfilStats,
        notairePremium: state.notairePremium,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getUserProfilStat: notairePremiumActions.getUserProfilStat },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotairePremiumLeadsStats)
