import { footerServices } from '../../services/footer.services.js'
import { footerConstants } from '../../constants/footer.constants'
import { res } from '../../helpers/dispatch'

export const footerActions = {
    getFooter,
    updateFooter,
}

function getFooter(id) {
    return (dispatch) => {
        dispatch(res.loading(footerConstants.FOOTER_LOADING))
        footerServices
            .getFooter(id)
            .then((response) => {
                let footer = response.data
                dispatch(res.success(footerConstants.GETFOOTER_SUCCESS, footer))
            })
            .catch((error) => {
                dispatch(res.failure(footerConstants.GETFOOTER_FAILURE, error))
            })
    }
}

function updateFooter(body, footer_id) {
    return (dispatch) => {
        dispatch(res.loading(footerConstants.FOOTER_LOADING))
        footerServices
            .updateFooter(body, footer_id)
            .then((response) => {
                let footer = response.data
                dispatch(res.success(footerConstants.PUTFOOTER_SUCCESS, footer))
            })
            .catch((error) => {
                dispatch(res.failure(footerConstants.PUTFOOTER_FAILURE, error))
            })
    }
}
