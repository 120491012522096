import { APINotreNotaire } from '../helpers/AxiosAPI'

export const notairepremiumServices = {
    getNotairesPremium,
    getNotairePremium,
    getNotaire,
    updateNotaire,
    getUserLeads,
    getUserNotes,
    getUserProfilStat,
    getUserCities,
    filtreNotairesPremium,
    getNotairesPremiumNoWebsite,
}

function getNotairesPremium(page) {
    return APINotreNotaire.get(`get_user_clients/?page=${page}`)
}
function getNotairesPremiumNoWebsite(page) {
    return APINotreNotaire.get(`get_user_clients/website/false/?page=${page}`)
}

function filtreNotairesPremium(filtre, page) {
    return APINotreNotaire.get(
        `get_user_clients/?page=${page}&nom=${filtre.nom}&ville=${filtre.ville}&signe=${filtre.signe}&nbLead=${filtre.nbLead}`
    )
}

function getNotairePremium(id) {
    return APINotreNotaire.get(`get_user_client/${id}`)
}

function getNotaire(id) {
    return APINotreNotaire.get(`get_user/${id}`)
}

function updateNotaire(id, body) {
    return APINotreNotaire.put(`update_user/${id}`, body)
}

function getUserLeads(id) {
    return APINotreNotaire.get(`get_user_client_leads/${id}`)
}

function getUserNotes(id) {
    return APINotreNotaire.get(`get_user_client_notes/${id}`)
}

function getUserProfilStat(id) {
    return APINotreNotaire.get(`user_premium_stats/${id}`)
}

function getUserCities(id) {
    return APINotreNotaire.get(`user_premium_cities/${id}`)
}
