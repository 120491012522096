import { leadsConstants } from '../constants/leads.constants'

export function lead(state = [], action) {
    switch (action.type) {
        case leadsConstants.GETLEADS_REQUEST:
            return action.payload
        case leadsConstants.GETLEAD_REQUEST:
            return [action.payload]
        case leadsConstants.DELETELEAD_REQUEST:
            return state.filter(({ id }) => id !== action.payload)
        default:
            return state
    }
}
