import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { chartsActions } from '../../../actions/charts.actions'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'

class Departements extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                nom: '',
                nbLeads: 0,
                map: false,
            },
            valideData: [],
            dataFilter: [],
        }

        this.filter = this.filter.bind(this)
        this.handleMap = this.handleMap.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        const { departements } = this.props.departements
        let departementsStat = []
        if (prevProps !== this.props) {
            if (Object.keys(departements).length !== 0) {
                departements.nom.map((departement) => {
                    let nbnotaire = departements.notaire.find(
                        (nbnotaire) =>
                            nbnotaire.departement === departement.departement
                    )
                    let nbnotaire_prenium = departements.notaire_premium.find(
                        (nbnotaire) =>
                            nbnotaire.departement === departement.departement
                    )
                    let nblead = departements.leads.find(
                        (nbnotaire) =>
                            nbnotaire.departement === departement.departement
                    )
                    let nbquotation_request =
                        departements.quotation_requests.find(
                            (nbnotaire) =>
                                nbnotaire.departement ===
                                departement.departement
                        )

                    departementsStat.push({
                        id: departement.departement,
                        nom: departement.nom,
                        notaire:
                            nbnotaire === undefined ? 0 : nbnotaire.notaire,
                        notaire_premium:
                            nbnotaire_prenium === undefined
                                ? 0
                                : nbnotaire_prenium.notaire_premium,
                        leads:
                            nblead === undefined ? 0 : parseInt(nblead.nb_lead),
                        quotation_request:
                            nbquotation_request === undefined
                                ? 0
                                : nbquotation_request.nb_quotation_request,
                    })
                })
            }

            this.setState({
                dataFilter: departementsStat,
                valideData: departementsStat,
            })
        }
        if (this.state.filter !== prevState.filter) {
            let newData = []
            let dep = isNaN(parseInt(this.state.filter.nom))
                ? ''
                : parseInt(this.state.filter.nom).toString()
            let nom = this.state.filter.nom.replace(/[0-9]|\s/g, '')
            newData = this.state.valideData.filter((departementStat) => {
                return (
                    departementStat.nom.includes(nom) &&
                    departementStat.id.includes(dep) &&
                    departementStat.leads >= this.state.filter.nbLeads
                )
            })

            this.setState({ dataFilter: newData })
        }
    }

    componentDidMount() {
        this.props.getDepartementsStat()
        this.props.getNotairePremiumMap()
    }

    filter(e) {
        let name = e.target.name
        let value = e.target.value
        this.setState((prevState) => ({
            filter: { ...prevState.filter, [name]: value },
        }))
    }

    handleMap() {
        this.setState({ map: !this.state.map })
    }

    render() {
        const { dataFilter } = this.state

        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <h4>Filtre :</h4>
                    </div>
                    <div className="col-5">
                        <input
                            type="text"
                            name="nom"
                            onChange={this.filter}
                            placeholder="Nom ou numéro de département"
                        />
                    </div>
                    <div className="col-4">
                        <input
                            type="number"
                            name="nbLeads"
                            placeholder="Plus de X leads"
                            onChange={this.filter}
                        />
                    </div>
                    <div className="col-3">
                        <button
                            className="btn btn-block btn-primary"
                            onClick={this.handleMap}
                        >
                            {this.state.map
                                ? 'Masquer la carte des Notaires Abonnés'
                                : 'Afficher la carte des Notaires Abonnés'}
                        </button>
                    </div>

                    <div
                        className={this.state.map ? 'col-3 row' : 'col-12 row'}
                    >
                        {dataFilter.map((departementStat) => (
                            <div
                                className={this.state.map ? 'col-12' : 'col-3'}
                                key={departementStat.id}
                            >
                                <div className="card-website">
                                    <div className="card-fiche-infos">
                                        <h2>
                                            <Link
                                                to={`statistique/departement/${departementStat.id}`}
                                            >
                                                {departementStat.id} -{' '}
                                                {departementStat.nom}{' '}
                                            </Link>
                                        </h2>
                                        <p>
                                            <b
                                                style={{
                                                    fontSize: '14px',
                                                    color: '#1edd98',
                                                }}
                                            >
                                                {
                                                    departementStat.notaire_premium
                                                }
                                            </b>{' '}
                                            notaire(s) abonné(s) /{' '}
                                            <b
                                                style={{
                                                    fontSize: '14px',
                                                    color: '#1edd98',
                                                }}
                                            >
                                                {departementStat.notaire}
                                            </b>{' '}
                                            notaire(s)
                                        </p>
                                        <p>
                                            Au cours des 90 dernier jours :
                                            <br />
                                            <b
                                                style={{
                                                    fontSize: '14px',
                                                    color: '#1edd98',
                                                }}
                                            >
                                                {departementStat.leads}
                                            </b>{' '}
                                            lead(s){' '}
                                            <b
                                                style={{
                                                    fontSize: '14px',
                                                    color: '#1edd98',
                                                }}
                                            >
                                                {
                                                    departementStat.quotation_request
                                                }
                                            </b>{' '}
                                            demande(s) devis
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="col-9 mt-4"
                        style={
                            this.state.map
                                ? { height: '80vh', display: 'block' }
                                : { display: 'none' }
                        }
                    >
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: 'AIzaSyDi4PirM_wFNcwrqQtgN8s6hu-K4nGyAgs',
                            }}
                            defaultCenter={{
                                lat: 46.95,
                                lng: 0.33,
                            }}
                            defaultZoom={6}
                        >
                            {this.props.notaireMap.notaireMap.map(
                                (notairMap) => (
                                    <div
                                        key={notairMap.id}
                                        lat={notairMap.latitude}
                                        lng={notairMap.longitude}
                                        style={{
                                            width: '5px',
                                            height: '5px',
                                            borderRadius: '5px',
                                            border: '5px solid red',
                                        }}
                                    ></div>
                                )
                            )}
                        </GoogleMapReact>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        departements: state.departements,
        notaireMap: state.notaireMap,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getDepartementsStat: chartsActions.getDepartementsStat,
            getNotairePremiumMap: chartsActions.getNotairePremiumMap,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Departements)
