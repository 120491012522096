import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { contactActions } from '../../actions/contact.actions'
import Loader from '../../components/Loader/Loader'
import { DateTime } from 'luxon'
class Contact extends Component {
    constructor(props) {
        super(props)

        this.checkboxHandler = this.checkboxHandler.bind(this)
    }

    componentDidMount() {
        if (this.props.contact.contact.length === 0) {
            this.props.getContact()
        }
    }

    checkboxHandler(id, call) {
        const data = {
            contact: {
                call: !!(call == null || call === false),
            },
        }
        this.props.updateContact(id, data)
    }

    render() {
        const { contact, loading } = this.props.contact
        return loading ? (
            <Loader />
        ) : (
            <div className="containerAdmin">
                <div className="header">
                    <h2>Notaires à contacter</h2>
                </div>
                <hr />
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Prénom</th>
                            <th scope="col">Mail</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col">Date de la demande</th>
                            <th>Appeler</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {contact.map((user) => (
                            <tr key={user.id}>
                                <td>{user.nom}</td>
                                <td>{user.prenom}</td>
                                <td>{user.email}</td>
                                <td>{user.telephone}</td>
                                <td>
                                    {DateTime.fromISO(user.created_at)
                                        .setLocale('fr')
                                        .toFormat('dd LLL yyyy')}
                                </td>
                                <td>
                                    <label className="switch">
                                        <input
                                            className="ml-5 mr-5"
                                            type="checkbox"
                                            checked={
                                                !(
                                                    user.call == null ||
                                                    user.call === false
                                                )
                                            }
                                            onChange={() =>
                                                this.checkboxHandler(
                                                    user.id,
                                                    user.call
                                                )
                                            }
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        type="button"
                                        onClick={() =>
                                            this.props.deleteContact(user.id)
                                        }
                                    >
                                        Supprimer
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        contact: state.contact,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getContact: contactActions.getContact,
            updateContact: contactActions.updateContact,
            deleteContact: contactActions.deleteContact,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
