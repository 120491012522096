import { APINotreNotaire } from '../helpers/AxiosAPI'

export const chartsServices = {
    getLeadsZone,
    getLeadsMonth,
    getLeadsStatuts,
    getTurnover,
    getDemandeDevis,
    getDemandeDevisZone,
    getUsersConnection,
    getQuotationConversionRate,
    getQuotationAccept,
    getDepartementsStat,
    getDepartementStat,
    getNotairePremiumMap,
}

function getLeadsZone() {
    return APINotreNotaire.get('zones_prospection')
}

function getLeadsMonth() {
    return APINotreNotaire.get('leads_count_month')
}

function getLeadsStatuts() {
    return APINotreNotaire.get('leads_statuts_stats')
}

function getTurnover() {
    return APINotreNotaire.get('get_turnover')
}

function getDemandeDevis() {
    return APINotreNotaire.get('demande_devis_count_month')
}

function getDemandeDevisZone() {
    return APINotreNotaire.get('zones_demande_devis')
}

function getUsersConnection(jours) {
    return APINotreNotaire.get('user_connection/' + jours)
}

function getQuotationConversionRate() {
    return APINotreNotaire.get('taux_transformation_devis/')
}

function getQuotationAccept() {
    return APINotreNotaire.get('devis_accepter/')
}

function getDepartementsStat() {
    return APINotreNotaire.get('departements_stat')
}

function getDepartementStat(id) {
    return APINotreNotaire.get('departements_stat/' + id)
}

function getNotairePremiumMap() {
    return APINotreNotaire.get('notaire_abonne_map/')
}
