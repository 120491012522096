import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modalActions } from '../../actions/modal.actions'

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//ReactModal.setAppElement('#yourAppElement')

class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            hiddenClose: false,
        }
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    componentDidMount() {
        ReactModal.setAppElement('#root')
    }

    handleOpenModal() {
        this.setState({ showModal: true })
    }

    handleCloseModal() {
        this.props.hideModal(this.props.id)
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
        if (nextProps.modal.modalOpen !== this.props.modal.modalOpen) {
            if (nextProps.modal.id === this.props.id)
                this.setState({
                    showModal: nextProps.modal.modalOpen,
                })
        }
        if (nextProps.modal.hiddenClose !== this.props.modal.hiddenClose) {
            this.setState({
                hiddenClose: nextProps.modal.hiddenClose,
            })
        }
    }

    render() {
        return (
            <div>
                <a
                    className={this.props.btnStyle}
                    style={
                        this.props.style
                            ? this.props.style
                            : { cursor: 'pointer' }
                    }
                    onClick={() => {
                        this.props.showModal(this.props.id)
                    }}
                >
                    {this.props.btnTitle}
                    {this.props.icon ? (
                        <FontAwesomeIcon icon={this.props.icon} />
                    ) : null}
                </a>
                <ReactModal
                    isOpen={this.state.showModal}
                    overlayClassName="OverlayModal"
                    className="Modal"
                    ariaHideApp={false}
                >
                    <div className={this.state.hiddenClose ? 'hidden' : null}>
                        <button
                            type="button"
                            onClick={this.handleCloseModal}
                            className="btn"
                        >
                            {' '}
                            <FontAwesomeIcon icon="times" />
                        </button>
                    </div>
                    {this.props.children}
                </ReactModal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        modal: state.modal,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideModal: modalActions.hideModal,
            showModal: modalActions.showModal,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
