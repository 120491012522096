import React, { Component, Fragment } from 'react'
import { notaireActions } from '../../../actions/notaire.actions.js'
import { Field, reduxForm } from 'redux-form'
import CheckBoxField from '../../../components/Input/CheckBoxField'
import DatePickerField from '../../../components/Input/DatePickerField'
import CkEditorField from '../../../components/Input/CkEditorField'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class FicheForm extends Component {
    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(value) {
        let data = {
            nom: value.nom,
            telephone: value.telephone,
            site_internet: value.site_internet,
            annnee_creation: value.annnee_creation,
            prenom: value.prenom,
            email_contact: value.email_contact,
            nom_entreprise: value.nom_entreprise,
            dispo: value.dispo,
            adresse: value.adresse,
            contact_phonenumber: value.contact_phonenumber,
            visio: value.visio,
            signature_electronique: value.signature_electronique,
            chapeau: value.chapeau,
            description: value.description,
        }
        this.props.updateFiche(data, value.id, value)
    }

    render() {
        return (
            <Fragment>
                <form name="fiche_form">
                    <div className="row mt-3">
                        <div className="col">
                            <div className="form-group">
                                <label className="">Nom de famille</label>
                                <Field component="input" name="nom" />
                            </div>
                            <div className="form-group">
                                <label className="">N° téléphone</label>
                                <Field component="input" name="telephone" />
                            </div>
                            <div className="form-group">
                                <label className="">Site internet</label>
                                <Field component="input" name="site_internet" />
                            </div>
                            <div className="form-group">
                                <label className="">
                                    Date de prestation de serment
                                </label>
                                <Field
                                    component={DatePickerField}
                                    name="annee_creation"
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="">Prénom</label>
                                <Field component="input" name="prenom" />
                            </div>
                            <div className="form-group">
                                <label className="">Email</label>
                                <Field component="input" name="email_contact" />
                            </div>
                            <div className="form-group">
                                <label className="">Nom de l'étude</label>
                                <Field
                                    component="input"
                                    name="nom_entreprise"
                                />
                            </div>
                            <p>
                                Disponible{' '}
                                <Field component={CheckBoxField} name="dispo" />
                            </p>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="">Adresse de l'étude</label>
                                <Field component="input" name="adresse" />
                            </div>
                            <div className="form-group">
                                <label className="">
                                    Téléphone mobile notification
                                </label>
                                <Field
                                    component="input"
                                    name="contact_phonenumber"
                                />
                            </div>
                            <p>
                                Signature electronique{' '}
                                <Field
                                    component={CheckBoxField}
                                    name="signature_electronique"
                                />
                            </p>
                            <p>
                                Visio{' '}
                                <Field component={CheckBoxField} name="visio" />
                            </p>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="">Phrase de presentation liste</label>
                        <Field component="input" name="chapeau" />
                    </div>
                    <div className="form-group">
                        <label className="">Description</label>
                        <Field component={CkEditorField} name="description" />
                    </div>
                    <div className="text-right">
                        <button
                            onClick={this.props.handleSubmit(this.handleSubmit)}
                            type="button"
                            id="fiche_form"
                            className="btn btn-primary"
                        >
                            Enregistrer les modifications
                        </button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { updateFiche: notaireActions.updateFiche },
        dispatch
    )
}

export default reduxForm({ form: 'fiche_form' })(
    connect(null, mapDispatchToProps)(FicheForm)
)
