import React, { Component } from 'react'
import FicheHorrairesHeader from './FicheHorrairesHeader'
import HorrairesCards from './Horraires/HorrairesCards'
import { bindActionCreators } from 'redux'
import { notaireActions } from '../../../../actions/notaire.actions.js'
import { connect } from 'react-redux'
import Loader from '../../../../components/Loader/Loader'

class FicheHorraires extends Component {
    componentDidMount() {
        if (this.props.horraires.horraires.length === 0) {
            this.props.getHorraires(this.props.fiche.fiche.id)
        }
    }

    render() {
        const { loading } = this.props.horraires

        return loading ? (
            <Loader />
        ) : (
            <div className="containerCardsFiche">
                <FicheHorrairesHeader />
                <HorrairesCards />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        horraires: state.horraires,
        fiche: state.fiche,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getHorraires: notaireActions.getHorraires },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FicheHorraires)
