import React, { Component } from 'react'
import Template1 from '../../../assets/images/template-1.png'
import Template2 from '../../../assets/images/template-2.png'
import Template3 from '../../../assets/images/template-3.png'

class RadioThemeField extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { name, onChange } = this.props.input

        return (
            <div>
                <label className="label_item">
                    <input
                        type="radio"
                        value="1"
                        name={name}
                        className="radio"
                        onChange={onChange}
                    />
                    <img
                        src={Template1}
                        style={{
                            width: '300px',
                            padding: '10px',
                            margin: '30px',
                        }}
                    />
                    <a
                        href="https://georges.notre-notaire.fr"
                        target="_blank"
                        style={{ display: 'block' }}
                        rel="noreferrer"
                    >
                        Voir la demo
                    </a>
                </label>
                <label className="label_item">
                    <input
                        type="radio"
                        value="3"
                        name={name}
                        className="radio"
                        onChange={onChange}
                    />
                    <img
                        src={Template2}
                        style={{
                            width: '300px',
                            padding: '10px',
                            margin: '30px',
                        }}
                    />
                    <a
                        href="https://matt.notre-notaire.fr/"
                        target="_blank"
                        style={{ display: 'block' }}
                        rel="noreferrer"
                    >
                        Voir la demo
                    </a>
                </label>
                <label className="label_item">
                    <input
                        type="radio"
                        value="2"
                        name={name}
                        className="radio"
                        onChange={onChange}
                    />
                    <img
                        src={Template3}
                        style={{
                            width: '300px',
                            padding: '10px',
                            margin: '30px',
                        }}
                    />
                    <a
                        href="https://brad.notre-notaire.fr/"
                        target="_blank"
                        style={{ display: 'block' }}
                        rel="noreferrer"
                    >
                        Voir la demo
                    </a>
                </label>
            </div>
        )
    }
}

export default RadioThemeField
