import { APINotreNotaire } from '../helpers/AxiosAPI'

export const teammateServices = {
    updateTeammate,
    getTeammate,
    postTeammate,
}

function updateTeammate(member, teammate_id) {
    let formData = new FormData()
    for (const property in member) {
        if (member[property] != null) {
            formData.append(property, member[property])
        }
    }
    return APINotreNotaire.put(`teammates/${teammate_id}`, formData)
}

function getTeammate(id) {
    return APINotreNotaire.get(`teammates/${id}`)
}

function postTeammate(member, equipe_id) {
    let formData = new FormData()
    for (const property in member) {
        if (member[property] != null) {
            formData.append(property, member[property])
        }
    }
    return APINotreNotaire.post(`create_since_admin/${equipe_id}`, formData)
}
