import React, { Component } from 'react'
import Loader from '../../../components/Loader/Loader'
import Image from '../../../../assets/images/placeholder.png'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { connect } from 'react-redux'

class NotaireCard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { loading, notairesPremium, pages } = this.props.notairesPremium

        return loading ? (
            <Loader />
        ) : (
            <div className="premium-list row">
                {notairesPremium.map((notaire) => (
                    <div className="col-6" key={notaire.id}>
                        <div className="card-fiche">
                            <img
                                src={
                                    notaire.photo.photo.url === null
                                        ? Image
                                        : notaire.photo.photo.url
                                }
                            />
                            <div className="card-fiche-infos">
                                <Link to={`/websites/add/${notaire.id}`}>
                                    <h2>
                                        {notaire.nom} - {notaire.prenom}{' '}
                                    </h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
                <div className={'col-12 row justify-content-center mt-4'}>
                    <ReactPaginate
                        previousLabel={'Précendent'}
                        nextLabel={'Suivant'}
                        pageCount={pages}
                        onPageChange={this.props.loadPage}
                        containerClassName="pagination paginate"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        activeClassName="page-item active"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { notairesPremium: state.notairesPremium }
}

export default connect(mapStateToProps)(NotaireCard)
