import { APINotreNotaire } from '../helpers/AxiosAPI'

export const fichesServices = {
    getFiche,
    updateFicheAdmin,
    updatePassword,
    updateFicheAdminPhoto,
}

function getFiche(id) {
    return APINotreNotaire.get(`fiches/${id}`)
}

export function updateFicheAdmin(body, fiche_id) {
    return APINotreNotaire.put(`update_fiche_admin/${fiche_id}`, body)
}

export function updateFicheAdminPhoto(body, fiche_id) {
    let formData = new FormData()
    for (const property in body) {
        if (body[property] != null) {
            formData.append(property, body[property])
        }
    }
    return APINotreNotaire.put(`update_fiche_admin/${fiche_id}`, formData)
}

function updatePassword(body, fiche_id) {
    return APINotreNotaire.put(`update_password/${fiche_id}`, body)
}
