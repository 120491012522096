import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { chartsActions } from '../../actions/charts.actions'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

class UserConnections extends Component {
    constructor(props) {
        super(props)
        this.onChangeJour = this.onChangeJour.bind(this)
    }

    componentDidMount() {
        this.props.getUsersConnection(14)
    }

    onChangeJour(e) {
        this.props.getUsersConnection(e.target.value)
    }

    render() {
        return (
            <div>
                <h3>Utilisateurs connecter :</h3>
                <div className="form-inline">
                    <p className="text-center">
                        Dans les
                        <select
                            className="form-control mb-2 mr-sm-2"
                            onChange={this.onChangeJour}
                        >
                            <option value="14">14</option>
                            <option value="30">30</option>
                        </select>
                        jours
                    </p>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Prénom</th>
                            <th scope="col">Mail</th>
                            <th scope="col">Dernière connexion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.usersConnection.usersConnection.map(
                            (user) => (
                                <tr key={user.id}>
                                    <td>{user.nom}</td>
                                    <td>{user.prenom}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        {DateTime.fromISO(user.lastsign)
                                            .setLocale('fr')
                                            .toFormat('dd LLL yyyy')}
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        usersConnection: state.usersConnection,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getUsersConnection: chartsActions.getUsersConnection },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserConnections)
