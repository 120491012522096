import { fichesServices } from '../services/fiches.services.js'
import { ficheConstants } from '../constants/fiche.constants'
import { horrairesService } from '../services/horaires.services.js'
import { horrairesConstants } from '../constants/horaires.constants'
import { res } from '../helpers/dispatch'

export const notaireActions = {
    createHorraire,
    getFiche,
    getHorraires,
    updateFiche,
    updateFichePhoto,
    updatePassword,
    deleteHorraire,
}

function createHorraire(horraire, fiche) {
    return (dispatch) => {
        dispatch(res.loading(horrairesConstants.HORRAIRES_LOADING))
        horrairesService
            .createHorraire(horraire, fiche)
            .then((response) => {
                let horraire = response.data
                dispatch(
                    res.success(
                        horrairesConstants.POSTHORRAIRE_SUCCESS,
                        horraire
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(horrairesConstants.POSTHORRAIRE_FAILURE, error)
                )
            })
    }
}

function getFiche(id) {
    return (dispatch) => {
        dispatch(res.loading(ficheConstants.FICHE_LOADING))
        fichesServices
            .getFiche(id)
            .then((response) => {
                let fiche = response.data
                dispatch(res.success(ficheConstants.GETFICHE_SUCCESS, fiche))
            })
            .catch((error) => {
                dispatch(res.failure(ficheConstants.GETFICHE_FAILURE, error))
            })
    }
}

function getHorraires(id) {
    return (dispatch) => {
        dispatch(res.loading(horrairesConstants.HORRAIRES_LOADING))
        horrairesService
            .getHorraires(id)
            .then((response) => {
                let horraires = response.data
                dispatch(
                    res.success(
                        horrairesConstants.GETHORRAIRES_SUCCESS,
                        horraires
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(horrairesConstants.GETHORRAIRES_FAILURE, error)
                )
            })
    }
}

function updateFiche(body, fiche_id, fiche) {
    return (dispatch) => {
        dispatch(res.loading(ficheConstants.FICHE_LOADING))
        fichesServices
            .updateFicheAdmin(body, fiche_id)
            .then(() => {
                dispatch(res.success(ficheConstants.PUTFICHE_SUCCESS, fiche))
            })
            .catch((error) => {
                dispatch(res.failure(ficheConstants.PUTFICHE_FAILURE, error))
            })
    }
}

function updateFichePhoto(body, fiche_id) {
    return (dispatch) => {
        dispatch(res.loading(ficheConstants.FICHE_LOADING))
        fichesServices
            .updateFicheAdminPhoto(body, fiche_id)
            .then((response) => {
                let fiche = response.data
                dispatch(res.success(ficheConstants.PUTFICHE_SUCCESS, fiche))
            })
            .catch((error) => {
                dispatch(res.failure(ficheConstants.PUTFICHE_FAILURE, error))
            })
    }
}

function updatePassword(body, fiche_id, fiche) {
    return (dispatch) => {
        dispatch(res.loading(ficheConstants.FICHE_LOADING))
        fichesServices
            .updatePassword(body, fiche_id)
            .then(() => {
                dispatch(res.success(ficheConstants.PUTFICHE_SUCCESS, fiche))
            })
            .catch((error) => {
                dispatch(res.failure(ficheConstants.PUTFICHE_FAILURE, error))
            })
    }
}

function deleteHorraire(id) {
    return (dispatch) => {
        dispatch(res.loading(horrairesConstants.HORRAIRES_LOADING))
        horrairesService
            .deleteHorraire(id)
            .then(() => {
                dispatch(
                    res.success(horrairesConstants.DELETEHORRAIRE_SUCCESS, id)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        horrairesConstants.DELETEHORRAIRE_FAILURE,
                        error
                    )
                )
            })
    }
}
