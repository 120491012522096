import { chartsConstants } from '../constants/charts.constants.js'

const leadsZoneState = {
    loading: true,
    leadsZone: [],
    error: false,
}

export function leadsZone(state = leadsZoneState, action) {
    switch (action.type) {
        case chartsConstants.GETLEADSZONE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETLEADSZONE_SUCCESS:
            return {
                ...state,
                leadsZone: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const leadsMonthState = {
    loading: true,
    leadsMonth: [],
    error: false,
}

export function leadsMonth(state = leadsMonthState, action) {
    switch (action.type) {
        case chartsConstants.GETLEADSMONTH_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETLEADSMONTH_SUCCESS:
            return {
                ...state,
                leadsMonth: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const leadsStatutsState = {
    loading: true,
    leadsStatuts: [],
    error: false,
}

export function leadsStatuts(state = leadsStatutsState, action) {
    switch (action.type) {
        case chartsConstants.GETLEADSTATUT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETLEADSTATUT_SUCCESS:
            return {
                ...state,
                leadsStatuts: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const turnoverState = {
    loading: true,
    turnover: [],
    error: false,
}

export function turnover(state = turnoverState, action) {
    switch (action.type) {
        case chartsConstants.GETTURNOVER_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETTURNOVER_SUCCESS:
            return {
                ...state,
                turnover: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const demandeDevisState = {
    loading: true,
    demandeDevis: null,
    error: false,
}

export function demandeDevis(state = demandeDevisState, action) {
    switch (action.type) {
        case chartsConstants.GETDEMANDEDEVIS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETDEMANDEDEVIS_SUCCESS:
            return {
                ...state,
                demandeDevis: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const demandeDevisZoneState = {
    loading: true,
    demandeDevisZone: [],
    error: false,
}

export function demandeDevisZone(state = demandeDevisZoneState, action) {
    switch (action.type) {
        case chartsConstants.GETDEMANDEDEVISZONES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETDEMANDEDEVISZONES_SUCCESS:
            return {
                ...state,
                demandeDevisZone: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const usersConnectionState = {
    loading: true,
    usersConnection: [],
    error: false,
}

export function usersConnection(state = usersConnectionState, action) {
    switch (action.type) {
        case chartsConstants.GETUSERCONNECTIONS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETUSERCONNECTIONS_SUCCESS:
            return {
                ...state,
                usersConnection: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const quotationConversionRateState = {
    loading: true,
    quotationConversionRate: null,
    error: false,
}

export function quotationConversionRate(
    state = quotationConversionRateState,
    action
) {
    switch (action.type) {
        case chartsConstants.GETQUOTATIONCONVERSIONRATE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETQUOTATIONCONVERSIONRATE_SUCCESS:
            return {
                ...state,
                quotationConversionRate: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const quotationAcceptState = {
    loading: true,
    quotationAccept: null,
    error: false,
}

export function quotationAccept(state = quotationAcceptState, action) {
    switch (action.type) {
        case chartsConstants.GETQUOTATIONACCEPT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETQUOTATIONACCEPT_SUCCESS:
            return {
                ...state,
                quotationAccept: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const departementsState = {
    loading: true,
    departements: {},
    error: false,
}

export function departements(state = departementsState, action) {
    switch (action.type) {
        case chartsConstants.GETDEPARTEMENTSSTAT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETDEPARTEMENTSSTAT_SUCCESS:
            return {
                ...state,
                departements: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const departementState = {
    loading: true,
    departement: {},
    error: false,
}

export function departement(state = departementState, action) {
    switch (action.type) {
        case chartsConstants.GETDEPARTEMENTSTAT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case chartsConstants.GETDEPARTEMENTSTAT_SUCCESS:
            return {
                ...state,
                departement: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const notaireMapState = {
    loading: true,
    notaireMap: [],
    error: false,
}

export function notaireMap(state = notaireMapState, action) {
    switch (action.type) {
        case chartsConstants.GETNOTAIREMAP_SUCCESS:
            return {
                ...state,
                notaireMap: action.payload.data,
                loading: false,
            }
        default:
            return state
    }
}
