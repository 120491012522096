import { DateTime } from 'luxon'

export function statsHelper() {
    const monthCorrespondance = {
        1: 'Janvier',
        2: 'Février',
        3: 'Mars',
        4: 'Avril',
        5: 'Mai',
        6: 'Juin',
        7: 'Juillet',
        8: 'Août',
        9: 'Septembre',
        10: 'Octobre',
        11: 'Novembre',
        12: 'Décembre',
    }
    let label = []
    let year = DateTime.now().year
    let month = DateTime.now().month
    let tabIndex = 0
    let correspondance = {}
    for (let i = month + 1; i <= 12; i++) {
        label[tabIndex] = monthCorrespondance[i]
        if (i < 10) {
            i = `0${i}`
        }
        correspondance[`${year - 1}-${i}-01`] = tabIndex
        tabIndex++
    }
    for (let i = 1; i <= month; i++) {
        label[tabIndex] = monthCorrespondance[i]
        if (i < 10) {
            i = `0${i}`
        }
        correspondance[`${year}-${i}-01`] = tabIndex
        tabIndex++
    }

    return { correspondance: correspondance, label: label }
}
