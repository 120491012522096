import { teammateConstants } from '../../constants/teammate.constants'
import { websiteConstants } from '../../constants/website.constants'

const teammateState = {
    loading: true,
    teammate: [],
    error: false,
}

export function teammate(state = teammateState, action) {
    switch (action.type) {
        case teammateConstants.TEAMMATE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case teammateConstants.GETTEAMMATE_SUCCESS:
            return {
                ...state,
                loading: false,
                teammate: action.payload.data,
            }
        case teammateConstants.POSTTEAMMATE_SUCCESS:
            state.teammate.push(action.payload.data)
            return {
                ...state,
                loading: false,
                teammate: state.teammate,
            }
        case teammateConstants.PUTTEAMMATE_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            let indexMembreUpdate = state.teammate.findIndex(
                (membre) => membre.id === action.payload.data.id
            )
            state.teammate.splice(indexMembreUpdate, 1, action.payload.data)
            return {
                ...state,
                loading: false,
                teammate: state.teammate,
            }
        case websiteConstants.DESTROY_WEBSITE:
            return teammateState
        default:
            return state
    }
}
