import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import EquipeForm from './EquipeForm'
import { equipeActions } from '../../../../actions/Website/equipe.actions.js'
import Teammate from './Teammate/Teammate'
import Loader from '../../../../components/Loader/Loader'
import Modal from '../../../../components/Modal/Modal'
import TeammateForm from './Teammate/TeammateForm'
import { teammateActions } from '../../../../actions/Website/teammate.actions'
import { modalActions } from '../../../../actions/modal.actions'

class Equipe extends Component {
    constructor(props) {
        super(props)
        this.submit = this.submit.bind(this)
    }

    componentDidMount() {
        if (this.props.equipe.equipe === null) {
            this.props.getEquipe(this.props.website.website.id)
        }
    }

    submit(value) {
        this.props.createTeammate(value, this.props.equipe.equipe.id)
        this.props.hideModal('NewMembre')
    }

    render() {
        const { loading, equipe } = this.props.equipe
        return loading ? (
            <Loader />
        ) : (
            <div className="containerCardsFiche">
                <EquipeForm initialValues={equipe} />
                <div className="mt-5 row">
                    <div className="col-6">
                        <h2>Les membres de votre équipe:</h2>
                    </div>
                    <div className="col-6 text-right">
                        <Modal
                            style={{
                                color: '#1fdd98',
                                fontSize: '18px',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                            }}
                            id="NewMembre"
                            btnTitle="👨‍💼 Ajouter un nouveau membre"
                        >
                            <Fragment>
                                <h2 className="text-center">
                                    Ajouter un membre
                                </h2>
                                <TeammateForm onSubmit={this.submit} />
                            </Fragment>
                        </Modal>
                    </div>
                </div>
                <Teammate />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        equipe: state.equipe,
        website: state.website,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEquipe: equipeActions.getEquipe,
            createTeammate: teammateActions.createTeammate,
            getTeammate: teammateActions.getTeammate,
            hideModal: modalActions.hideModal,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Equipe)
