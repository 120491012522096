import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { notairespremiumActions } from '../../../actions/notairespremium.actions'
import { connect } from 'react-redux'
import NotaireCard from './NotaireCard'

class WebsiteAdd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current_page: 1,
        }

        this.loadPage = this.loadPage.bind(this)
    }

    componentDidMount() {
        this.props.getNotairesPremiumNoWebsite(this.state.current_page)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.current_page !== prevState.current_page) {
            this.props.getNotairesPremiumNoWebsite(this.state.current_page)
        }
    }

    loadPage(page) {
        let newPage = page.selected + 1
        this.setState({ current_page: newPage })
    }

    render() {
        return (
            <div className="containerAdmin">
                <h2 className="text-center">Création site internet</h2>
                <h3>Choisir un notaire : </h3>
                <NotaireCard loadPage={this.loadPage} />
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getNotairesPremiumNoWebsite:
                notairespremiumActions.getNotairesPremiumNoWebsite,
        },
        dispatch
    )
}

export default connect(null, mapDispatchToProps)(WebsiteAdd)
