import { userstatistiquesConstants } from '../constants/userstatistiques.constants'
import { userstatistiquesServices } from '../services/userstatistiques.services.js'
import { res } from '../helpers/dispatch'

export const statsUsersActions = {
    getUserStatistiques,
}

function getUserStatistiques() {
    return (dispatch) => {
        dispatch(res.loading(userstatistiquesConstants.GETUSERSTATS_LOADING))
        userstatistiquesServices
            .getUserStatistiques()
            .then((response) => {
                let statsUsers = response.data
                dispatch(
                    res.success(
                        userstatistiquesConstants.GETUSERSTATS_SUCCESS,
                        statsUsers
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        userstatistiquesConstants.GETUSERSTATS_FAILURE,
                        error
                    )
                )
            })
    }
}
