import { APINotreNotaire } from '../helpers/AxiosAPI'

export const contactServices = {
    getContact,
    updateContact,
    deleteContact,
}

function getContact() {
    return APINotreNotaire.get('demande_infos')
}

function updateContact(id, data) {
    return APINotreNotaire.put('demande_infos/' + id, data)
}

function deleteContact(id) {
    return APINotreNotaire.delete('demande_infos/' + id)
}
