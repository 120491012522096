import { websiteConstants } from '../../constants/website.constants'
import { websiteService } from '../../services/website.services.js'
import { res } from '../../helpers/dispatch'
import { history } from '../../helpers/history'

export const websiteAction = {
    getWebsites,
    getWebsite,
    updateWebsite,
    createWebsite,
    destroyWebsite,
}

function getWebsites() {
    return (dispatch) => {
        dispatch(res.loading(websiteConstants.WEBSITES_LOADING))
        websiteService
            .getWebsites()
            .then((response) => {
                let websites = response.data
                dispatch(
                    res.success(websiteConstants.GETWEBSITES_SUCCESS, websites)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(websiteConstants.GETWEBSITES_FAILURE, error)
                )
            })
    }
}

function getWebsite(id) {
    return (dispatch) => {
        dispatch(res.loading(websiteConstants.WEBSITE_LOADING))
        websiteService
            .getWebsite(id)
            .then((response) => {
                let website = response.data
                dispatch(
                    res.success(websiteConstants.GETWEBSITE_SUCCESS, website)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(websiteConstants.GETWEBSITE_FAILURE, error)
                )
            })
    }
}

function createWebsite(website) {
    return (dispatch) => {
        websiteService
            .createWebsite(website)
            .then(() => {
                history.push('/websites')
            })
            .catch(() => {
                dispatch()
            })
    }
}

function updateWebsite(website, website_id) {
    return (dispatch) => {
        dispatch(res.loading(websiteConstants.WEBSITE_LOADING))
        websiteService
            .updateWebsite(website, website_id)
            .then(() => {
                dispatch(
                    res.success(websiteConstants.PUTWEBSITE_SUCCESS, website)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(websiteConstants.PUTWEBSITE_FAILURE, error)
                )
            })
    }
}

function destroyWebsite() {
    return (dispatch) => {
        dispatch(res.success(websiteConstants.DESTROY_WEBSITE, null))
    }
}
