import React, { Component } from 'react'
import Template1 from '../../../../../assets/images/template-1.png'
import Template3 from '../../../../../assets/images/template-3.png'
import Template2 from '../../../../../assets/images/template-2.png'
import { bindActionCreators } from 'redux'
import { websiteAction } from '../../../../actions/Website/website.actions.js'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import ColorBoxField from '../../../../components/Input/ColorBoxField'

class InfoForm extends Component {
    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(value) {
        this.props.updateWebsite(value, value.id, value.color)
    }

    render() {
        const website = this.props.initialValues

        let templates = {
            1: (
                <img
                    src={Template1}
                    style={{ width: '800px', padding: '10px', margin: '30px' }}
                />
            ),
            2: (
                <img
                    src={Template3}
                    style={{ width: '800px', padding: '10px', margin: '30px' }}
                />
            ),
            3: (
                <img
                    src={Template2}
                    style={{ width: '800px', padding: '10px', margin: '30px' }}
                />
            ),
        }

        return (
            <div>
                <form onSubmit={this.handleSubmit} id="info_form">
                    <h4> Nom de domaine:</h4>
                    <div className="text-center mb-5">
                        <h3>
                            <a
                                href={'http://' + website.domain_name}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {website.domain_name}
                            </a>
                        </h3>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <h4>Model de site:</h4>
                            <div className="theme_choice text-center">
                                {templates[website.template_id]}
                            </div>
                        </div>
                        <div className="col-6">
                            <h4>
                                Choix de la couleur de votre site (modifiable à
                                tout moment):
                            </h4>
                            <Field name="color" component={ColorBoxField} />
                        </div>
                    </div>
                    <label>
                        <b>Atl SEO:</b>
                    </label>
                    <Field component="input" type="text" name="alt_seo" />
                    <label>
                        <b>URL SEO:</b>
                    </label>
                    <Field component="input" type="text" name="url_seo" />
                    <div className="text-right mt-5">
                        <button
                            type="button"
                            id="info_form"
                            onClick={this.props.handleSubmit(this.handleSubmit)}
                            className="btn btn-primary"
                        >
                            Enregistrer les modifications
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { updateWebsite: websiteAction.updateWebsite },
        dispatch
    )
}

export default reduxForm({ form: 'info_form' })(
    connect(null, mapDispatchToProps)(InfoForm)
)
