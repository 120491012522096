export const notairepremiumConstants = {
    NOTAIRE_PREMIUM_LOADING: 'NOTAIRE_PREMIUM_LOADING',
    NOTAIRES_PREMIUM_LOADING: 'NOTAIRES_PREMIUM_LOADING',
    GETNOTAIRES_PREMIUM_SUCCESS: 'GET_NOTAIRES_PREMIUM_SUCCESS',
    GETNOTAIRES_PREMIUM_FAILURE: 'GET_NOTAIRES_PREMIUM_FAILURE',
    FILTRENOTAIRES_PREMIUM_SUCCESS: 'FILTRE_NOTAIRES_PREMIUM_SUCCESS',
    GETNOTAIRE_PREMIUM_SUCCESS: 'GET_NOTAIRE_PREMIUM_SUCCESS',
    FINDNOTAIRE_PREMIUM_SUCCESS: 'FIND_NOTAIRE_PREMIUM_SUCCESS',
    GETNOTAIRE_PREMIUM_FAILURE: 'GET_NOTAIRE_PREMIUM_FAILURE',
    GETNOTAIRE_REQUEST: 'GET_NOTAIRE_REQUEST',
    UPDATENOTAIRE_REQUEST: 'UPDATE_NOTAIRE_REQUEST',
    DESTROY_NOTAIREPREMIUM: 'DESTROY_NOTAIREPREMIUM',
}
