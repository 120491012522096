import React, { Component } from 'react'
import IconLogo from '../../../../assets/images/IconLogo.svg'
import NavBarItem from './NavBarItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userActions } from '../../../actions/auth.actions'
import { connect } from 'react-redux'

class NavBar extends Component {
    render() {
        return (
            <div className="navbar">
                <nav>
                    <ul>
                        <li className="text-center">
                            <IconLogo
                                style={{ width: '50px', height: '60px' }}
                            />
                        </li>
                        <NavBarItem
                            name="stats"
                            url="/statistique"
                            icon="chart-area"
                        />
                        <NavBarItem name="users" url="/users" icon="user-tie" />
                        <NavBarItem
                            name="research"
                            url="/research"
                            icon="search"
                        />
                        <NavBarItem
                            name="attribution"
                            url="/leads"
                            icon="people-arrows"
                        />
                        <NavBarItem
                            name="website"
                            url="/websites"
                            icon="globe"
                        />
                        <NavBarItem
                            name="newclient"
                            url="/contact"
                            icon="comments"
                        />

                        <li>
                            <a onClick={this.props.logout}>
                                <FontAwesomeIcon
                                    style={{
                                        color: 'white',
                                        fontSize: '1.5em',
                                    }}
                                    icon="sign-out-alt"
                                />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}

const actionCreators = {
    logout: userActions.logout,
}

export default connect(null, actionCreators)(NavBar)
