import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { notairespremiumActions } from '../../actions/notairespremium.actions.js'
import UserStatistiques from './UserStatistiques/UserStatistiques'
import UserCard from './UserCard/UserCard'
import UsersHeader from './UsersHeader/UsersHeader'
import UserFiltre from './UserFiltre/UserFiltre'

class Users extends Component {
    constructor(props) {
        super(props)

        this.state = {
            current_page: 1,
        }

        this.isFiltre = false
        this.filtres = {}

        this.loadPage = this.loadPage.bind(this)
    }

    componentDidMount() {
        this.props.getNotairesPremium(this.state.current_page)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.current_page !== prevState.current_page) {
            if (this.isFiltre) {
                this.props.filtreNotairesPremium(
                    this.filtres,
                    this.state.current_page
                )
            } else {
                this.props.getNotairesPremium(this.state.current_page)
            }
        }
    }

    loadPage(page) {
        let newPage = page.selected + 1
        this.setState({ current_page: newPage })
    }

    filtresUser(filtres) {
        this.setState({ current_page: 1 })
        this.props.filtreNotairesPremium(filtres, this.state.current_page)
        this.filtres = filtres
        this.isFiltre = true
    }

    render() {
        return (
            <div className="containerAdmin">
                <UsersHeader />
                <hr />
                <UserFiltre
                    onSubmit={(values) => this.filtresUser(values)}
                    initialValues={{
                        nom: '',
                        signe: '',
                        nbLead: '',
                        ville: '',
                    }}
                />
                <div className="row">
                    <div className="col-9 bloc-premium">
                        <UserCard loadPage={this.loadPage} />
                    </div>
                    <div className="col-3">
                        <UserStatistiques />
                    </div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            filtreNotairesPremium: notairespremiumActions.filtreNotairesPremium,
            getNotairesPremium: notairespremiumActions.getNotairesPremium,
        },
        dispatch
    )
}

export default connect(null, mapDispatchToProps)(Users)
