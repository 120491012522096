import React, { Component, Fragment } from 'react'
import { equipeActions } from '../../../../actions/Website/equipe.actions.js'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import InputCountCharField from '../../../../components/Input/InputCountCharField'
import TextareaCountCharField from '../../../../components/Input/TextareaCountCharField'

class EquipeForm extends Component {
    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(value) {
        this.props.updateEquipe(value, value.id)
    }

    render() {
        return (
            <Fragment>
                <form id="update_equipe">
                    <div className="padding_form">
                        <label>
                            <b>Titre:</b>
                        </label>
                        <p style={{ color: '#33ab9f' }}>
                            Choisissez un titre de page court avec maximum 20
                            characters
                        </p>
                        <div>
                            <Field
                                name="title"
                                component={InputCountCharField}
                            />
                        </div>
                    </div>
                    <div className="padding_form">
                        <label>
                            <b>Description:</b>
                        </label>
                        <p style={{ color: '#33ab9f' }}>
                            Nous vous conseillons un maximum de 800 characters
                        </p>
                        <div>
                            <Field
                                name="description"
                                component={TextareaCountCharField}
                                style={{ height: '200px' }}
                            />
                        </div>
                    </div>
                    <div className="text-right mt-3">
                        <button
                            type="button"
                            id="update_equipe"
                            onClick={this.props.handleSubmit(this.handleSubmit)}
                            className="btn btn-primary"
                        >
                            Enregistrer les modifications
                        </button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

export default reduxForm({ form: 'update_equipe' })(
    connect(null, { updateEquipe: equipeActions.updateEquipe })(EquipeForm)
)
