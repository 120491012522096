import React, { Component, Fragment } from 'react'
import { notairepremiumServices } from '../../services/notairespremium.services.js'
import { updateNotaire } from '../../actions/notairePremium.actions'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

class NotaireHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: false,
            id: null,
        }

        this.checkboxHandler = this.checkboxHandler.bind(this)
    }

    componentDidMount() {
        if (this.props.fiche.fiche !== null) {
            notairepremiumServices
                .getNotaire(this.props.fiche.fiche.user_id)
                .then((response) => {
                    this.setState({
                        checked:
                            response.data.subscribed === null
                                ? false
                                : response.data.subscribed,
                        id: response.data.id,
                    })
                })
        }
    }

    checkboxHandler() {
        this.setState(
            (prevState) => ({
                checked: !prevState.checked,
            }),
            function () {
                updateNotaire(this.state.id, {
                    user: {
                        subscribed: this.state.checked,
                        subscribed_at: DateTime.now(),
                    },
                })
            }
        )
    }

    render() {
        const { fiche } = this.props.fiche
        return (
            <Fragment>
                <div className="header">
                    <h2>Fiche de maître {fiche.nom}</h2>
                </div>
                {this.state.checked ? null : (
                    <a
                        href={`https://www.notre-notaire.fr/demande_infos/new?id=${fiche.id}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <p>Lien de prospection</p>
                    </a>
                )}
                <div className="header-premium">
                    <form>
                        <label className="mr-2">Client </label>
                        <label className="switch">
                            <input
                                className="ml-5 mr-5"
                                type="checkbox"
                                name="subscribed"
                                onChange={this.checkboxHandler}
                                checked={this.state.checked}
                            />
                            <span className="slider round"></span>
                        </label>
                    </form>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return { fiche: state.fiche }
}

export default connect(mapStateToProps, null)(NotaireHeader)
