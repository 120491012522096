import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { websiteAction } from '../../actions/Website/website.actions.js'
import { connect } from 'react-redux'
import Loader from '../../components/Loader/Loader'

class WebsiteList extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.websites.websites.length === 0) {
            this.props.getWebsites()
        }
    }

    render() {
        const { loading, websites } = this.props.websites

        return loading ? (
            <Loader />
        ) : (
            <div className="premium-list">
                {websites.map((website) => (
                    <div className="card-website" key={website.id}>
                        <div className="card-fiche-infos">
                            <Link to={`websites/edit/${website.id}`}>
                                <h2>
                                    {website.user.fiche.nom} -{' '}
                                    {website.user.fiche.prenom}{' '}
                                </h2>
                            </Link>
                            <p>URL: {website.domain_name}</p>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { websites: state.websites }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getWebsites: websiteAction.getWebsites },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteList)
