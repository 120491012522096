import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { notairePremiumActions } from '../../actions/notairePremium.actions'
import NotreNotaireHeader from './NotairePremiumHeader/NotreNotaireHeader'
import NotairePremiumLeads from './NotairePremiumStats/NotairePremiumLeads'
import NotairePremiumNotes from './NotairePremiumStats/NotairePremiumNotes'
import NotairePremiumLeadsStats from './NotairePremiumStats/NotairePremiumLeadsStats'
import NotairePremiumCities from './NotairePremiumStats/NotairePremiumCities'
import Loader from '../../components/Loader/Loader'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

class NotairePremium extends Component {
    componentDidMount() {
        if (this.props.notairePremium.notairePremium === null) {
            this.props.getNotairePremium(this.props.match.params.id)
        }
    }

    componentWillUnmount() {
        this.props.destroyNotairePremium()
    }

    render() {
        const { loading } = this.props.notairePremium

        return loading ? (
            <Loader />
        ) : (
            <div className="containerAdmin">
                <NotreNotaireHeader />
                <hr />
                <Tabs>
                    <TabList>
                        <Tab>Leads</Tab>
                        <Tab>Villes</Tab>
                        <Tab>Avis</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="row">
                            <div className="col-7 leads_notes">
                                <NotairePremiumLeads />
                            </div>
                            <div className="col-5 leads_notes">
                                <NotairePremiumLeadsStats />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <NotairePremiumCities />
                    </TabPanel>
                    <TabPanel>
                        <NotairePremiumNotes />
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}
function mapStateToProps(state, ownProps) {
    const id = parseInt(ownProps.match.params.id, 10)
    let notairePremium = state.notairePremium
    if (state.notairesPremium.notairesPremium.length !== 0) {
        notairePremium.notairePremium =
            state.notairesPremium.notairesPremium.find(
                (notaire) => notaire.user_id === id
            )
        notairePremium.loading = false
    }
    return { notairePremium }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getNotairePremium: notairePremiumActions.getNotairePremium,
            destroyNotairePremium: notairePremiumActions.destroyNotairePremium,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(NotairePremium)
