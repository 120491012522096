import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import OfficeForm from './OfficeForm'
import { officeActions } from '../../../../actions/Website/office.actions.js'
import Loader from '../../../../components/Loader/Loader'

class Office extends Component {
    componentDidMount() {
        if (this.props.office.office === null) {
            this.props.getOffice(this.props.website.website.id)
        }
    }

    render() {
        const { loading, office } = this.props.office

        return loading ? (
            <Loader />
        ) : (
            <div className="containerCardsFiche">
                <OfficeForm initialValues={office} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        website: state.website,
        office: state.office,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getOffice: officeActions.getOffice }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Office)
