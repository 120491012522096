import React, { Component } from 'react'
import FooterForm from './FooterForm'
import { bindActionCreators } from 'redux'
import { footerActions } from '../../../../actions/Website/footer.actions.js'
import { connect } from 'react-redux'
import Loader from '../../../../components/Loader/Loader'

class Footer extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.footer.footer === null) {
            this.props.getFooter(this.props.website.website.id)
        }
    }

    render() {
        const { loading, footer } = this.props.footer

        return loading ? (
            <Loader />
        ) : (
            <div className="containerCardsFiche">
                <FooterForm initialValues={footer} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        footer: state.footer,
        website: state.website,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getFooter: footerActions.getFooter }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
