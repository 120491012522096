import { usercitiesConstants } from '../../constants/usercities.constants'
import { notairepremiumConstants } from '../../constants/notairespremium.constants'

const userCitiesState = {
    loading: true,
    userCities: [],
    error: false,
}

export function userCities(state = userCitiesState, action) {
    switch (action.type) {
        case usercitiesConstants.GETUSERCITIES_SUCCESS:
            return {
                ...state,
                userCities: action.payload.data,
                loading: false,
            }
        case notairepremiumConstants.DESTROY_NOTAIREPREMIUM:
            return userCitiesState
        default:
            return state
    }
}
