import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { notairePremiumActions } from '../../../actions/notairePremium.actions'
import { DateTime } from 'luxon'
import Loader from '../../../components/Loader/Loader'

class NotairePremiumLeads extends Component {
    componentDidMount() {
        if (this.props.notairePremium.notairePremium !== null) {
            if (this.props.userLeads.userLeads.length === 0) {
                this.props.getUserLeads(
                    this.props.notairePremium.notairePremium.user_id
                )
            }
        }
    }

    render() {
        const { loading, userLeads } = this.props.userLeads

        return loading ? (
            <Loader />
        ) : (
            <Fragment>
                <h4>
                    <u>Leads: {userLeads.length}</u>
                </h4>
                <div className="bloc_user_lead">
                    <br />
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Catégory</th>
                                <th scope="col">Statut</th>
                                <th scope="col">Source</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userLeads.map((lead) => (
                                <tr key={lead.id}>
                                    <th>{lead.id}</th>
                                    <td>{lead.nom}</td>
                                    <td>{lead.category.category_name}</td>
                                    <td>{lead.lead_statuts.statut}</td>
                                    <td>{lead.source}</td>
                                    <td>
                                        {DateTime.fromISO(lead.created_at)
                                            .setLocale('fr')
                                            .toFormat('dd LLL yyyy')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        userLeads: state.userLeads,
        notairePremium: state.notairePremium,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getUserLeads: notairePremiumActions.getUserLeads },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(NotairePremiumLeads)
