import React, { Component, Fragment } from 'react'
import ArticlesList from './ArticlesList/ArticlesList'
import { connect } from 'react-redux'
import Modal from '../../../../components/Modal/Modal'
import ArticleForm from './ArticleForm/ArticleForm'
import { bindActionCreators } from 'redux'
import { articleActions } from '../../../../actions/Website/article.actions'
import { modalActions } from '../../../../actions/modal.actions'

class Articles extends Component {
    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
    }

    submit(value) {
        value.web_id = this.props.website.website.id
        value.online = false
        this.props.createArticle(value)
        this.props.hideModal('NewArticle')
    }
    render() {
        return (
            <Fragment>
                <div className="containerCardsFiche">
                    <div className="bar row mb-4">
                        <div className="col-6 offset-6 text-right">
                            <Modal
                                id="NewArticle"
                                btnStyle="btn btn-primary"
                                btnTitle="Créer un article"
                            >
                                <Fragment>
                                    <h2 className="text-center">
                                        Ajouter un article
                                    </h2>
                                    <ArticleForm onSubmit={this.submit} />
                                </Fragment>
                            </Modal>
                        </div>
                    </div>
                    <div className="bloc_conseil">
                        <p>
                            <b>💡 Quelques idées de sujets d'articles :</b>
                        </p>
                        <ul>
                            <li>
                                De manière mensuelle ou trimestrielle, vous
                                pouvez écrire un article sur le marché
                                immobilier de votre ville/département.
                            </li>
                            <li>Actualités liées à la vie de votre étude</li>
                            <li>
                                Essayez d’expliquer votre métier et de le rendre
                                accessible à tous
                            </li>
                            <li>
                                N’hésitez pas à nous contacter si vous souhaitez
                                des idées d’articles à créer, nous sommes à
                                votre disposition.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <ArticlesList />
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        website: state.website,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createArticle: articleActions.createArticle,
            hideModal: modalActions.hideModal,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles)
