import { APINotreNotaire } from '../helpers/AxiosAPI'

export const officeServices = {
    updateOffice,
    getOffice,
}

function updateOffice(office, office_id) {
    let formData = new FormData()
    for (const property in office) {
        if (office[property] != null) {
            formData.append(property, office[property])
        }
    }
    return APINotreNotaire.put(`offices/${office_id}`, formData)
}

function getOffice(id) {
    return APINotreNotaire.get(`offices/${id}`)
}
