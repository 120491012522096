import { userleadsConstants } from '../../constants/userleads.constants'
import { notairepremiumConstants } from '../../constants/notairespremium.constants'

const userLeadsState = {
    loading: true,
    userLeads: [],
    error: false,
}

export function userLeads(state = userLeadsState, action) {
    switch (action.type) {
        case userleadsConstants.GETUSERLEADS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case userleadsConstants.GETUSERLEADS_SUCCESS:
            return {
                ...state,
                userLeads: action.payload.data,
                loading: false,
            }
        case notairepremiumConstants.DESTROY_NOTAIREPREMIUM:
            return userLeadsState
        default:
            return state
    }
}
