import { usernotesConstants } from '../../constants/usernotes.constants'
import { notairepremiumConstants } from '../../constants/notairespremium.constants'

const userNotesState = {
    loading: true,
    userNotes: [],
    error: false,
}

export function userNotes(state = userNotesState, action) {
    switch (action.type) {
        case usernotesConstants.GETUSERNOTES_SUCCESS:
            return {
                ...state,
                userNotes: action.payload.data,
                loading: false,
            }
        case notairepremiumConstants.DESTROY_NOTAIREPREMIUM:
            return userNotesState
        default:
            return state
    }
}
