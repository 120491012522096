import React, { Component, Fragment } from 'react'
import { notaireActions } from '../../../actions/notaire.actions.js'
import { Field, reduxForm } from 'redux-form'
import InputPhotoField from '../../../components/Input/InputPhotoField'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class PhotoFicheForm extends Component {
    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(value) {
        let data = {
            photo: value.photo,
        }
        this.props.updateFichePhoto(data, value.id)
    }

    render() {
        return (
            <Fragment>
                <form name="photo_form">
                    <Field
                        component={InputPhotoField}
                        style={{
                            width: '240px',
                            height: '240px',
                            borderRadius: '8px',
                            margin: 'auto',
                            display: 'block',
                        }}
                        name="photo"
                    />
                    <div className="text-right">
                        <button
                            onClick={this.props.handleSubmit(this.handleSubmit)}
                            type="button"
                            id="photo_form"
                            className="btn btn-primary"
                        >
                            Enregistrer les modifications
                        </button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { updateFichePhoto: notaireActions.updateFichePhoto },
        dispatch
    )
}

export default reduxForm({ form: 'photo_form' })(
    connect(null, mapDispatchToProps)(PhotoFicheForm)
)
