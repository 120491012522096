import React from 'react'
import algoliasearch from 'algoliasearch'
import { Link } from 'react-router-dom'
import { InstantSearch, SearchBox, connectHits } from 'react-instantsearch-dom'

const searchClient = algoliasearch(
    'TD6LEGX67A',
    '5dfef93259570f289d3f75ca885059c3'
)

const FicheHit = ({ hits }) => {
    return (
        <div className="row">
            {hits.map((notaire) => (
                <div className="col-4" key={notaire.objectID}>
                    <div className="card-fiche">
                        <div className="card-fiche-infos">
                            <Link to={`/notaire/${notaire.objectID}`}>
                                <h2>
                                    {notaire.nom} - {notaire.prenom}{' '}
                                </h2>
                            </Link>
                            <p>
                                <b
                                    style={{
                                        fontSize: '12px',
                                        color: '#1edd98',
                                    }}
                                >
                                    {notaire.email_contact}
                                </b>
                            </p>
                            <p>{notaire.adresse}</p>
                            <p>0{notaire.telephone}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

const CustomHits = connectHits(FicheHit)

const AlgoliaSearchNotaire = () => (
    <InstantSearch searchClient={searchClient} indexName="Fiche">
        <SearchBox />
        <CustomHits hitComponent={CustomHits} />
    </InstantSearch>
)

export default AlgoliaSearchNotaire
