import React, { Component } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import HomePage from './HomePage/HomePage'
import Loader from '../../../components/Loader/Loader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { websiteAction } from '../../../actions/Website/website.actions.js'
import InfoForm from './Info/InfoForm'
import Office from './Office/Office'
import Equipe from './Equipe/Equipe'
import Articles from './Articles/Articles'
import Footer from './Footer/Footer'

class WebSite extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getWebsite(this.props.match.params.id)
    }

    componentWillUnmount() {
        this.props.destroyWebsite()
    }

    render() {
        const { loading, website } = this.props.website

        return loading ? (
            <Loader />
        ) : (
            <div className="containerAdmin">
                <h2 className="text-center mt-5">
                    Site internet de Maitre {website.user.fiche.nom}
                </h2>
                <Tabs>
                    <TabList>
                        <Tab>Info</Tab>
                        <Tab>Home Page</Tab>
                        <Tab>Office</Tab>
                        <Tab>Equipe</Tab>
                        <Tab>Articles</Tab>
                        <Tab>Footer</Tab>
                    </TabList>

                    <TabPanel>
                        <InfoForm initialValues={website} />
                    </TabPanel>
                    <TabPanel>
                        <HomePage />
                    </TabPanel>
                    <TabPanel>
                        <Office />
                    </TabPanel>
                    <TabPanel>
                        <Equipe />
                    </TabPanel>
                    <TabPanel>
                        <Articles />
                    </TabPanel>
                    <TabPanel>
                        <Footer />
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const id = parseInt(ownProps.match.params.id, 10)
    let website = state.website
    if (state.websites.websites.length !== 0) {
        website.website = state.websites.websites.find(
            (website) => website.id === id
        )
        website.loading = false
    }
    return { website }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getWebsite: websiteAction.getWebsite,
            destroyWebsite: websiteAction.destroyWebsite,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(WebSite)
