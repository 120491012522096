import { notairepremiumConstants } from '../constants/notairespremium.constants'

const notairesPremiumState = {
    loading: true,
    notairesPremium: [],
    filtre: false,
    pages: 0,
    error: false,
}

export function notairesPremium(state = notairesPremiumState, action) {
    switch (action.type) {
        case notairepremiumConstants.NOTAIRE_PREMIUM_LOADING:
            return {
                ...state,
                loading: true,
            }
        case notairepremiumConstants.GETNOTAIRES_PREMIUM_SUCCESS:
            return {
                ...state,
                notairesPremium: action.payload.data.data,
                pages: parseInt(action.payload.data.pages),
                loading: false,
            }
        case notairepremiumConstants.FILTRENOTAIRES_PREMIUM_SUCCESS:
            return {
                ...state,
                notairesPremium: action.payload.data.data,
                pages: parseInt(action.payload.data.pages),
                filtre: true,
                loading: false,
            }
        default:
            return state
    }
}

const notairePremiumState = {
    loading: true,
    notairePremium: null,
    error: false,
}

export function notairePremium(state = notairePremiumState, action) {
    switch (action.type) {
        case notairepremiumConstants.NOTAIRE_PREMIUM_LOADING:
            return {
                ...state,
                loading: true,
            }
        case notairepremiumConstants.GETNOTAIRE_PREMIUM_SUCCESS:
            return {
                ...state,
                notairePremium: action.payload.data,
                loading: false,
            }
        case notairepremiumConstants.DESTROY_NOTAIREPREMIUM:
            return notairePremiumState
        default:
            return state
    }
}
