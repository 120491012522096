import { userstatistiquesConstants } from '../../constants/userstatistiques.constants'

const statsUsersState = {
    loading: true,
    statsUsers: [],
    error: false,
}

export function statsUsers(state = statsUsersState, action) {
    switch (action.type) {
        case userstatistiquesConstants.GETUSERSTATS_SUCCESS:
            return {
                ...state,
                statsUsers: action.payload.data,
                loading: false,
            }
        default:
            return state
    }
}
