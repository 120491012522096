import { ficheConstants } from '../../constants/fiche.constants'
import { horrairesConstants } from '../../constants/horaires.constants'

const ficheState = {
    loading: true,
    fiche: null,
    error: false,
}

export function fiche(state = ficheState, action) {
    switch (action.type) {
        case ficheConstants.FICHE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case ficheConstants.GETFICHE_SUCCESS:
            return {
                ...state,
                fiche: action.payload.data,
                loading: false,
                error: null,
            }
        case ficheConstants.PUTFICHE_SUCCESS:
            return {
                ...state,
                fiche: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}

const horrairesState = {
    loading: true,
    horraires: [],
    error: false,
}

export function horraires(state = horrairesState, action) {
    switch (action.type) {
        case horrairesConstants.HORRAIRES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case horrairesConstants.GETHORRAIRES_SUCCESS:
            return {
                ...state,
                horraires: action.payload.data,
                loading: false,
                error: null,
            }
        case horrairesConstants.POSTHORRAIRE_SUCCESS:
            state.horraires.push(action.payload.data)
            state.horraires.sort((a, b) => a.jour - b.jour)
            return {
                ...state,
                horraires: state.horraires,
                loading: false,
                error: null,
            }
        case horrairesConstants.DELETEHORRAIRE_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            let indexDelete = state.horraires.findIndex(
                (horraire) => horraire.id === action.payload.data
            )
            state.horraires.splice(indexDelete, 1)
            return {
                ...state,
                horraires: state.horraires,
                loading: false,
                error: null,
            }

        default:
            return state
    }
}
