import React, { Component } from 'react'
import IconLogo from '../../../assets/images/IconLogo.svg'
import { connect } from 'react-redux'
import { userActions } from '../../actions/auth.actions.js'

class Auth extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
            submitted: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    handleSubmit(e) {
        e.preventDefault()

        this.setState({ submitted: true })
        const { email, password } = this.state
        if (email && password) {
            this.props.login(email, password)
        }
    }

    render() {
        const { email, password, submitted } = this.state
        return (
            <div className="bkg">
                <div className="bloc_icon">
                    <IconLogo />
                    <div className="bloc_auth">
                        <h2>Espace Administrateur</h2>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className="containerInput">
                                <div
                                    className={
                                        submitted && !email ? ' has-error' : ''
                                    }
                                >
                                    <label htmlFor="email">email</label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                    />
                                    {submitted && !email && (
                                        <div className="help-block">
                                            email is required
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={
                                        submitted && !password
                                            ? ' has-error'
                                            : ''
                                    }
                                >
                                    <label htmlFor="password">
                                        Mot de passe
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={this.handleChange}
                                    />
                                    {submitted && !password && (
                                        <div className="help-block">
                                            Password is required
                                        </div>
                                    )}
                                </div>
                            </div>
                            <button className="btn btn-primary margin_top_20">
                                Se connecter
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication
    return { loggingIn }
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
}

export default connect(mapState, actionCreators)(Auth)
