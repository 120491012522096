import React, { Component } from 'react'
import { websiteAction } from '../../../actions/Website/website.actions.js'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { notaireActions } from '../../../actions/notaire.actions.js'
import Loader from '../../../components/Loader/Loader'
import ColorBoxField from '../../../components/Input/ColorBoxField'
import RadioThemeField from '../../../components/Input/RadioThemeField'
import InputDomainField from '../../../components/Input/InputDomainField'

class WebsiteAddNotaire extends Component {
    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.props.getFiche(this.props.match.params.id)
    }

    handleSubmit(value) {
        value.user_id = this.props.fiche.fiche.user_id
        value.live = false

        this.props.createWebsite(value)
    }

    render() {
        const { fiche, loading } = this.props.fiche
        return loading ? (
            <Loader />
        ) : (
            <div className="containerAdmin">
                <h2>Création du Website de Maitre {fiche.nom}</h2>
                <hr />
                <div className="row justify-content-md-center">
                    <form className="col-10">
                        <h3> Nom de domaine:</h3>
                        <Field
                            component={InputDomainField}
                            name="domain_name"
                        />
                        <h3>Choix du model de site:</h3>
                        <div className="theme_choice text-center mb-5">
                            <Field
                                component={RadioThemeField}
                                name="template_id"
                            />
                        </div>
                        <h3>Choix de la couleur de votre site:</h3>
                        <Field name="color" component={ColorBoxField} />
                        <input
                            type="button"
                            onClick={this.props.handleSubmit(this.handleSubmit)}
                            value="Valider la configuration"
                            className="btn btn-primary mt-5"
                        />
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { fiche: state.fiche }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getFiche: notaireActions.getFiche,
            createWebsite: websiteAction.createWebsite,
        },
        dispatch
    )
}

export default reduxForm({
    form: 'website',
    initialValues: { template_id: '1', color: '#7413a4' },
})(connect(mapStateToProps, mapDispatchToProps)(WebsiteAddNotaire))
