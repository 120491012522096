import { fetchAPI } from './Api'

export const leadsServices = {
    getLeads,
    getLead,
    deleteLead,
}

function getLeads(page) {
    return fetchAPI(`leads_no_attribution/?page=${page}`, 'GET')
}

function getLead(id) {
    return fetchAPI(`/leads/${id}`, 'GET')
}

function deleteLead(id) {
    return fetchAPI(`/leads/${id}`, 'DELETE')
}
