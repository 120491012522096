import React, { Component, Fragment } from 'react'
import { notaireActions } from '../../../../../actions/notaire.actions.js'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class HorrairesCards extends Component {
    dateFormat(date) {
        const d = new Date(date)
        return date === null
            ? 'Fermée'
            : `${d.getUTCHours()}h${
                  d.getUTCMinutes() === 0
                      ? `${d.getUTCMinutes()}` + 0
                      : d.getUTCMinutes()
              }`
    }

    handleDelete(id) {
        this.props.deleteHorraire(id)
    }

    render() {
        const { horraires } = this.props.horraires

        const day = {
            1: 'Lundi',
            2: 'Mardi',
            3: 'Mercredi',
            4: 'Jeudi',
            5: 'Vendredi',
            6: 'Samedi',
            7: 'Dimanche',
        }

        return (
            <Fragment>
                {horraires.map((horraire) => (
                    <div className="card_visio" key={horraire.id}>
                        <div className="card_visio_info">
                            <h3>{day[horraire.jour]}</h3>
                        </div>
                        <div className="card_visio_info">
                            <h2>
                                <u>Matin:</u>{' '}
                                {this.dateFormat(horraire.open_morning)} -{' '}
                                {this.dateFormat(horraire.close_morning)}
                            </h2>
                        </div>
                        <div className="card_visio_info">
                            <h2>
                                <u>Après-midi:</u>{' '}
                                {this.dateFormat(horraire.open_afternoon)} -{' '}
                                {this.dateFormat(horraire.close_afternon)}
                            </h2>
                        </div>
                        <div className="padding_cta">
                            <a
                                className="delete_a"
                                onClick={() => this.handleDelete(horraire.id)}
                            >
                                Supprimer
                            </a>
                        </div>
                    </div>
                ))}
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        horraires: state.horraires,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { deleteHorraire: notaireActions.deleteHorraire },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(HorrairesCards)
