import React, { Component, Fragment } from 'react'
import { footerActions } from '../../../../actions/Website/footer.actions.js'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class FooterForm extends Component {
    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(value) {
        this.props.updateFooter(value, value.id)
    }

    render() {
        return (
            <Fragment>
                <form id="footer_form">
                    <label>
                        <b>Description:</b>
                    </label>
                    <Field name="description" component="textarea" />
                    <div className="text-right mt-4">
                        <button
                            className="btn btn-primary"
                            type="button"
                            id="footer_form"
                            onClick={this.props.handleSubmit(this.handleSubmit)}
                        >
                            Enregistrer les modifications
                        </button>
                    </div>
                </form>
            </Fragment>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateFooter: footerActions.updateFooter,
        },
        dispatch
    )
}

export default reduxForm({ form: 'footer_form' })(
    connect(null, mapDispatchToProps)(FooterForm)
)
