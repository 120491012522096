import React, { Component } from 'react'

class InfoLead extends Component {
    render() {
        return (
            <div className="infoLead">
                <h3>Infos lead:</h3>
                <ul>
                    <li>
                        <b>{this.props.lead.category.category_name}</b>
                    </li>
                    <li>{this.props.lead.email}</li>
                    <li>{this.props.lead.telephone}</li>
                    <li>Source: {this.props.lead.source}</li>
                    <li>Département: {this.props.lead.geo.geo_cp}</li>
                    <li>Date: {this.props.lead.date}</li>
                    <li>Info: {this.props.lead.information}</li>
                    <li>Montant: {this.props.lead.montant}</li>
                </ul>
            </div>
        )
    }
}

export default InfoLead
