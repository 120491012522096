import { res } from '../helpers/dispatch'
import { notairepremiumConstants } from '../constants/notairespremium.constants'
import { notairepremiumServices } from '../services/notairespremium.services'
import { userleadsConstants } from '../constants/userleads.constants'
import { usernotesConstants } from '../constants/usernotes.constants'
import { userprofilstatsConstants } from '../constants/userprofilstats.constants'
import { usercitiesConstants } from '../constants/usercities.constants'

export const notairePremiumActions = {
    getNotairePremium,
    getUserLeads,
    getUserNotes,
    getUserProfilStat,
    getUserCities,
    destroyNotairePremium,
}

function getNotairePremium(id) {
    return (dispatch) => {
        dispatch(res.loading(notairepremiumConstants.NOTAIRE_PREMIUM_LOADING))
        notairepremiumServices
            .getNotairePremium(id)
            .then((response) => {
                let notairePremium = response.data
                dispatch(
                    res.success(
                        notairepremiumConstants.GETNOTAIRE_PREMIUM_SUCCESS,
                        notairePremium
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        notairepremiumConstants.GETNOTAIRE_PREMIUM_FAILURE,
                        error
                    )
                )
            })
    }
}

export function updateNotaire(id, body, callback) {
    const promise = notairepremiumServices.updateNotaire(id, body, callback)
    return {
        type: notairepremiumConstants.UPDATENOTAIRE_REQUEST,
        payload: promise,
    }
}

function getUserLeads(id) {
    return (dispatch) => {
        dispatch(res.loading(userleadsConstants.GETUSERLEADS_LOADING))
        notairepremiumServices
            .getUserLeads(id)
            .then((response) => {
                let userLeads = response.data
                dispatch(
                    res.success(
                        userleadsConstants.GETUSERLEADS_SUCCESS,
                        userLeads
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(userleadsConstants.GETUSERLEADS_FAILURE, error)
                )
            })
    }
}

function getUserNotes(id) {
    return (dispatch) => {
        dispatch(res.loading(usernotesConstants.GETUSERNOTES_LOADING))
        notairepremiumServices
            .getUserNotes(id)
            .then((response) => {
                let userNotes = response.data
                dispatch(
                    res.success(
                        usernotesConstants.GETUSERNOTES_SUCCESS,
                        userNotes
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(usernotesConstants.GETUSERNOTES_FAILURE, error)
                )
            })
    }
}

function getUserProfilStat(id) {
    return (dispatch) => {
        dispatch(
            res.loading(userprofilstatsConstants.GETUSERPROFIL_STATS_LOADING)
        )
        notairepremiumServices
            .getUserProfilStat(id)
            .then((response) => {
                let userNotes = response.data
                dispatch(
                    res.success(
                        userprofilstatsConstants.GETUSERPROFIL_STATS_SUCCESS,
                        userNotes
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        userprofilstatsConstants.GETUSERPROFIL_STATS_FAILLURE,
                        error
                    )
                )
            })
    }
}

function getUserCities(id) {
    return (dispatch) => {
        dispatch(res.loading(usercitiesConstants.GETUSERCITIES_LOADING))
        notairepremiumServices
            .getUserCities(id)
            .then((response) => {
                let userNotes = response.data
                dispatch(
                    res.success(
                        usercitiesConstants.GETUSERCITIES_SUCCESS,
                        userNotes
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        usercitiesConstants.GETUSERCITIES_FAILURE,
                        error
                    )
                )
            })
    }
}

function destroyNotairePremium() {
    return (dispatch) => {
        dispatch(
            res.success(notairepremiumConstants.DESTROY_NOTAIREPREMIUM, null)
        )
    }
}
