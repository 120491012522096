import React, { Component, Fragment } from 'react'
import NavBar from './NavBar/NavBar'
class Layout extends Component {
    render() {
        return (
            <Fragment>
                <NavBar />
                {this.props.children}
            </Fragment>
        )
    }
}

export default Layout
