import { userprofilstatsConstants } from '../../constants/userprofilstats.constants'
import { notairepremiumConstants } from '../../constants/notairespremium.constants'

const userProfilStatsState = {
    loading: true,
    userProfilStats: [],
    error: false,
}

export function userProfilStats(state = userProfilStatsState, action) {
    switch (action.type) {
        case userprofilstatsConstants.GETUSERPROFIL_STATS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case userprofilstatsConstants.GETUSERPROFIL_STATS_SUCCESS:
            return {
                ...state,
                userProfilStats: action.payload.data,
                loading: false,
            }
        case notairepremiumConstants.DESTROY_NOTAIREPREMIUM:
            return userProfilStatsState
        default:
            return state
    }
}
