import React, { Component } from 'react'
import LeadsZone from './LeadsZone/LeadsZone'
import LeadsMonth from './LeadsMonth/LeadsMonth'
import LeadStatut from './LeadStatut/LeadStatut'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import RequestQuotation from './RequestQuotation/RequestQuotation'
import RequestQuotationZone from './RequestQuotation/RequestQuotationZone'
import UserConnections from './UserConnections'
import QuotationConversionRate from './RequestQuotation/QuotationConversionRate'
import QuoationAccept from './RequestQuotation/QuoationAccept'
import Departements from './Departements/Departements'

class Statistiques extends Component {
    render() {
        return (
            <div className="containerAdmin">
                <div className="header">
                    <h2>Statistiques</h2>
                </div>
                <hr />
                <Tabs>
                    <TabList>
                        <Tab>Départements</Tab>
                        <Tab>Leads</Tab>
                        <Tab>Devis</Tab>
                        <Tab>Notaires</Tab>
                    </TabList>

                    <TabPanel>
                        <Departements />
                    </TabPanel>
                    <TabPanel>
                        <div className="row">
                            <div className="col-6">
                                <LeadStatut />
                            </div>
                            <div className="col-6">
                                <LeadsMonth />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="row">
                            <div className="col-6">
                                <RequestQuotation />
                            </div>
                            <div className="col-6">
                                <QuoationAccept />
                            </div>
                            <div className="col-6">
                                <QuotationConversionRate />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <UserConnections />
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default Statistiques
