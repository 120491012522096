import React, { Component } from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

class DatePickerField extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { name, value, onChange } = this.props.input

        return (
            <div>
                <DatePicker
                    name={name}
                    selected={value === '' ? null : new Date(value)}
                    onChange={onChange}
                />
            </div>
        )
    }
}

export default DatePickerField
