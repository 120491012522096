import { contactConstants } from '../constants/contact.constants'

const contactState = {
    loading: false,
    contact: [],
    error: false,
}

export function contact(state = contactState, action) {
    switch (action.type) {
        case (contactConstants.GETCONTACT_LOADING,
        contactConstants.UPDATECONTACT_LOADING,
        contactConstants.DELETECONTACT_LOADING):
            return {
                ...state,
                loading: true,
            }
        case contactConstants.GETCONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contact: action.payload.data,
            }
        case contactConstants.UPDATECONTACT_SUCCESS:
            state.contact.splice(
                state.contact.findIndex(
                    (contactone) => contactone.id === action.payload.data.id
                ),
                1,
                action.payload.data
            )
            return {
                ...state,
                loading: false,
                contact: state.contact,
            }
        case contactConstants.DELETECONTACT_SUCCESS:
            state.contact.splice(
                state.contact.findIndex(
                    (contactone) => contactone.id === action.payload.data
                ),
                1
            )
            return {
                ...state,
                loading: false,
                contact: state.contact,
            }
        default:
            return state
    }
}
