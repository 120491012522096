import React from 'react'
import { DateTime } from 'luxon'

export const LeadHeader = (props) => {
    return (
        <div className="header">
            <h2>
                {props.lastname}-{props.firstname}
            </h2>
            <p className="date">
                {DateTime.fromISO(props.created_at)
                    .setLocale('fr')
                    .toFormat('dd LLL yyyy')}
            </p>
        </div>
    )
}
