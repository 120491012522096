import React, { Component, Fragment } from 'react'
import TeammateForm from './TeammateForm'
import Modal from '../../../../../components/Modal/Modal'
import { bindActionCreators } from 'redux'
import { modalActions } from '../../../../../actions/modal.actions'
import { connect } from 'react-redux'
import { teammateActions } from '../../../../../actions/Website/teammate.actions'

class MemberCard extends Component {
    constructor(props) {
        super(props)
        this.submit = this.submit.bind(this)
    }

    submit(value) {
        this.props.updateTeammate(value, value.id)
        this.props.hideModal('EditMembre' + value.id)
    }

    render() {
        const { member } = this.props
        return (
            <Fragment>
                <div className="card_visio">
                    <img src={member.photo.url} />
                    <div
                        className="card_visio_info"
                        style={{ padding: '26px' }}
                    >
                        <h2>
                            {member.lastname} - {member.firstname}
                        </h2>
                        <p>
                            <b>Description:</b>
                            {member.description}
                        </p>
                    </div>
                    <div className="padding_cta">
                        <Modal id={'EditMembre' + member.id} btnTitle="Editer">
                            <Fragment>
                                <h2 className="text-center">
                                    Edition de {member.lastname}
                                </h2>
                                <TeammateForm
                                    initialValues={member}
                                    onSubmit={this.submit}
                                />
                            </Fragment>
                        </Modal>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideModal: modalActions.hideModal,
            updateTeammate: teammateActions.updateTeammate,
        },
        dispatch
    )
}

export default connect(null, mapDispatchToProps)(MemberCard)
