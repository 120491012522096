import { APINotreNotaire } from '../helpers/AxiosAPI'

export const websiteService = {
    createWebsite,
    getWebsite,
    getWebsites,
    updateWebsite,
}

function createWebsite(website) {
    return APINotreNotaire.post('websites', website)
}

function getWebsite(id) {
    return APINotreNotaire.get('websites/' + id)
}

function getWebsites() {
    return APINotreNotaire.get('websites')
}

function updateWebsite(website, website_id) {
    return APINotreNotaire.patch(`websites/${website_id}`, website)
}
