import { categoryServices } from '../services/category.services'
import { categoryConstants } from '../constants/category.constants'
import { res } from '../helpers/dispatch'

export const categoriesActions = {
    getCategories,
}

function getCategories() {
    return (dispatch) => {
        dispatch(res.loading(categoryConstants.GETCATEGORIES_LOADING))
        categoryServices
            .getCategories()
            .then((response) => {
                let categories = response.data
                dispatch(
                    res.success(
                        categoryConstants.GETCATEGORIES_SUCCESS,
                        categories
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(categoryConstants.GETCATEGORIES_FAILURE, error)
                )
            })
    }
}
