import { APINotreNotaire } from '../helpers/AxiosAPI'

export const articleServices = {
    updateArticle,
    getArticles,
    postArticle,
}

function updateArticle(article, article_id) {
    let formData = new FormData()
    for (const property in article) {
        if (article[property] != null) {
            formData.append(property, article[property])
        }
    }
    return APINotreNotaire.patch(`articles/${article_id}`, formData)
}

function getArticles(id) {
    return APINotreNotaire.get(`get_user_articles/${id}`)
}

function postArticle(article) {
    let formData = new FormData()
    for (const property in article) {
        if (article[property] != null) {
            formData.append(property, article[property])
        }
    }
    return APINotreNotaire.post('articles', formData)
}
