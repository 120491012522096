import React, { Component } from 'react'
import { connect } from 'react-redux'
import Image from '../../../../assets/images/placeholder.png'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import Loader from '../../../components/Loader/Loader'
import { DateTime } from 'luxon'

class UserCard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { loading, notairesPremium, pages } = this.props.notairesPremium

        return loading ? (
            <Loader />
        ) : (
            <div className="premium-list">
                {notairesPremium.map((notaire) => (
                    <div className="card-fiche" key={notaire.id}>
                        <img
                            src={
                                notaire.photo.photo.url === null
                                    ? Image
                                    : notaire.photo.photo.url
                            }
                        />
                        <div className="card-fiche-infos">
                            <Link to={`users/${notaire.user_id}`}>
                                <h2>
                                    {notaire.nom} - {notaire.prenom}{' '}
                                </h2>
                            </Link>
                            <p>
                                Ce notaire à{' '}
                                <b
                                    style={{
                                        fontSize: '14px',
                                        color: '#1edd98',
                                    }}
                                >
                                    {notaire.count_lead} leads
                                </b>{' '}
                                --- Dernier lead:{' '}
                                <b>
                                    {notaire.last_attribution === null
                                        ? 'No Lead'
                                        : DateTime.fromISO(
                                              notaire.last_attribution
                                                  .created_at
                                          )
                                              .setLocale('fr')
                                              .toFormat('dd LLL yyyy')}
                                </b>
                            </p>
                            <p>
                                Date de souscription:{' '}
                                <b
                                    style={{
                                        fontSize: '14px',
                                        color: '#1edd98',
                                    }}
                                >
                                    {notaire.user_date_susbscribed === null
                                        ? 'No date'
                                        : DateTime.fromISO(
                                              notaire.user_date_susbscribed
                                          )
                                              .setLocale('fr')
                                              .toFormat('dd LLL yyyy')}
                                </b>
                            </p>
                        </div>
                        <div
                            className="dispo"
                            style={{
                                marginInlineStart: 'auto',
                                marginRight: '4px',
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '18px',
                                    color: 'white',
                                    backgroundColor: '#1edd98',
                                    borderRadius: '4px',
                                    padding: '4px',
                                }}
                            >
                                Scoring: {notaire.scoring.toFixed(2)}
                            </p>
                        </div>
                    </div>
                ))}
                <div className={'row justify-content-center mt-4'}>
                    <ReactPaginate
                        previousLabel={'Précendent'}
                        nextLabel={'Suivant'}
                        pageCount={pages}
                        onPageChange={this.props.loadPage}
                        containerClassName="pagination paginate"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        activeClassName="page-item active"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { notairesPremium: state.notairesPremium }
}

export default connect(mapStateToProps)(UserCard)
