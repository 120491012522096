import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'
import Loader from '../../../components/Loader/Loader'
import { bindActionCreators } from 'redux'
import { chartsActions } from '../../../actions/charts.actions'
import { connect } from 'react-redux'
import { statsHelper } from '../../../helpers/StatsHelper'

class QuotationConversionRate extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getQuotationConversionRate()
    }

    render() {
        const { quotationConversionRate, loading } =
            this.props.quotationConversionRate
        const label = statsHelper().label
        const corespondance = statsHelper().correspondance

        let options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            max: 100,
                            min: 0,
                        },
                    },
                ],
            },
        }

        let dataset = []

        if (quotationConversionRate !== null) {
            quotationConversionRate.demandeDevis.map(
                (value) => (dataset[corespondance[value[0]]] = value[1])
            )
            quotationConversionRate.devis.map(
                (value) =>
                    (dataset[corespondance[value[0]]] = parseInt(
                        (value[1] / dataset[corespondance[value[0]]]) * 100
                    ))
            )
        }

        const data = {
            labels: label,
            datasets: [
                {
                    label: 'Taux de transforamtion',
                    backgroundColor: '#1edd98',
                    borderWidth: 1,
                    data: dataset,
                },
            ],
        }
        return loading ? (
            <Loader />
        ) : (
            <div className="containerStats">
                <h3>Taux de transforamtion par mois</h3>
                <Bar data={data} options={options} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        quotationConversionRate: state.quotationConversionRate,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getQuotationConversionRate:
                chartsActions.getQuotationConversionRate,
        },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuotationConversionRate)
