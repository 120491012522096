import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { chartsActions } from '../../../../actions/charts.actions'
import { connect } from 'react-redux'
import Loader from '../../../../components/Loader/Loader'
import { statsHelper } from '../../../../helpers/StatsHelper'
import { Bar } from 'react-chartjs-2'

class Departement extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getDepartementStat(this.props.match.params.id)
    }

    render() {
        const { departement, loading } = this.props.departement
        let moyenne = 0
        const label = statsHelper().label
        const corespondance = statsHelper().correspondance
        let dataPacs, dataMariage, dataSci, dataImmo, dataTestament
        dataPacs = []
        dataMariage = []
        dataSci = []
        dataImmo = []
        dataTestament = []
        let dataLeads = []
        if (Object.keys(departement).length !== 0) {
            if (departement.nb_quotation_by_user.length !== 0) {
                departement.nb_quotation_by_user.map(
                    (nb_quotation) => (moyenne += nb_quotation.nb_devis)
                )
                moyenne = moyenne / departement.nb_quotation_by_user.length
            }
            departement.devis.pacs.map(
                (value) => (dataPacs[corespondance[value[0]]] = value[1])
            )
            departement.devis.mariage.map(
                (value) => (dataMariage[corespondance[value[0]]] = value[1])
            )
            departement.devis.sci.map(
                (value) => (dataSci[corespondance[value[0]]] = value[1])
            )
            departement.devis.immo.map(
                (value) => (dataImmo[corespondance[value[0]]] = value[1])
            )
            departement.devis.testament.map(
                (value) => (dataTestament[corespondance[value[0]]] = value[1])
            )
            departement.liste_leads.map(
                (value) => (dataLeads[corespondance[value[0]]] = value[1])
            )
        }

        const options_devis = {
            scales: {
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
        }

        let data_leads = {
            labels: label,
            datasets: [
                {
                    label: 'Leads',
                    backgroundColor: '#2287ff',
                    data: dataLeads,
                },
            ],
        }

        const data_devis = {
            labels: label,
            datasets: [
                {
                    label: 'Pacs',
                    backgroundColor: '#2287ff',
                    data: dataPacs,
                },
                {
                    label: 'Mariage',
                    backgroundColor: '#FF863B',
                    data: dataMariage,
                },
                {
                    label: 'Immobilier',
                    backgroundColor: '#FFD908',
                    data: dataImmo,
                },
                {
                    label: 'Testament',
                    backgroundColor: '#14F9FF',
                    data: dataTestament,
                },
                {
                    label: 'SCI',
                    backgroundColor: '#3E2EFF',
                    data: dataSci,
                },
            ],
        }

        return loading ? (
            <Loader />
        ) : (
            <div className="containerAdmin">
                <div className="header">
                    <h2>
                        {this.props.match.params.id} - {departement.nom}
                    </h2>
                </div>
                <hr />
                <div className="row">
                    <div className="col-3">
                        <div className="card-website">
                            <div className="card-fiche-infos">
                                Nombre de notaire : <br />
                                <b
                                    style={{
                                        fontSize: '24px',
                                        color: '#1edd98',
                                    }}
                                >
                                    {departement.notaire}
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card-website">
                            <div className="card-fiche-infos">
                                Nombre de notaire abonnés : <br />
                                <b
                                    style={{
                                        fontSize: '24px',
                                        color: '#1edd98',
                                    }}
                                >
                                    {departement.notaire_premium}
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card-website">
                            <div className="card-fiche-infos">
                                Nombre de leads: <br />
                                <b
                                    style={{
                                        fontSize: '24px',
                                        color: '#1edd98',
                                    }}
                                >
                                    {departement.leads}
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card-website">
                            <div className="card-fiche-infos">
                                Nombre de demande de devis : <br />
                                <b
                                    style={{
                                        fontSize: '24px',
                                        color: '#1edd98',
                                    }}
                                >
                                    {
                                        this.props.departement.departement
                                            .quotation_requests
                                    }
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card-website">
                            <div className="card-fiche-infos">
                                Moyenne du nombre de devis par notaire : <br />
                                <b
                                    style={{
                                        fontSize: '24px',
                                        color: '#1edd98',
                                    }}
                                >
                                    {moyenne}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className=" col-6">
                        <h3>Nombre de demande de devis par mois</h3>
                        <Bar data={data_devis} options={options_devis} />
                    </div>
                    <div className=" col-6">
                        <h3>Nombre de lead par mois</h3>
                        <Bar data={data_leads} />
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        departement: state.departement,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getDepartementStat: chartsActions.getDepartementStat },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Departement)
