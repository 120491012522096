import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getTeammate } from '../../../../../actions/Website/teammate.actions.js'
import Loader from '../../../../../components/Loader/Loader'
import MemberCard from './MemberCard'
class Teammate extends Component {
    componentDidMount() {
        if (this.props.teammate.teammate.length === 0) {
            this.props.getTeammate(this.props.website.website.id)
        }
    }

    render() {
        const { loading, teammate } = this.props.teammate

        return loading ? (
            <Loader />
        ) : (
            <div className="mt-5">
                {teammate.map((member) => (
                    <MemberCard key={member.id} member={member} />
                ))}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        teammate: state.teammate,
        website: state.website,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getTeammate }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Teammate)
