import React, { Component } from 'react'
import { notaireActions } from '../../actions/notaire.actions.js'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import NotaireHeader from './NotaireHeader'
import FicheForm from './FicheForm/FicheForm'
import SpecialityForm from './FicheForm/SpecialityForm'
import PhotoFicheForm from './FicheForm/PhotoFicheForm'
import FicheHorraires from './FicheForm/FicheHorraires/FicheHorraires'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import PasswordFicheForm from './FicheForm/PasswordFicheForm'
import Loader from '../../components/Loader/Loader'

class Notaire extends Component {
    componentDidMount() {
        this.props.getFiche(this.props.match.params.id)
    }

    render() {
        const { loading, fiche } = this.props.fiche

        return loading ? (
            <Loader />
        ) : (
            <div className="containerAdmin">
                <NotaireHeader />
                <hr />
                <Tabs>
                    <TabList>
                        <Tab>Info</Tab>
                        <Tab>Specialités</Tab>
                        <Tab>Photo</Tab>
                        <Tab>Horraires</Tab>
                        <Tab>Mot de passe</Tab>
                    </TabList>

                    <TabPanel>
                        <FicheForm initialValues={fiche} />
                    </TabPanel>
                    <TabPanel>
                        <SpecialityForm initialValues={fiche} />
                    </TabPanel>
                    <TabPanel>
                        <PhotoFicheForm initialValues={fiche} />
                    </TabPanel>
                    <TabPanel>
                        <FicheHorraires />
                    </TabPanel>
                    <TabPanel>
                        <PasswordFicheForm />
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { fiche: state.fiche }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getFiche: notaireActions.getFiche }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Notaire)
