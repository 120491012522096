import axios from 'axios'

export const APINotreNotaire = axios.create({
    baseURL: process.env.API_URL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
})

APINotreNotaire.interceptors.request.use(
    (config) => {
        let user = JSON.parse(localStorage.getItem('user'))
        let token = user.token
        if (token) {
            config.headers.Authorization = token
        }
        return config
    },
    (error) => Promise.reject(error)
)
