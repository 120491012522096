import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const NavBarItem = (props) => {
    return (
        <li>
            <NavLink activeClassName="selected" strict to={props.url}>
                <FontAwesomeIcon
                    style={{ color: 'white', fontSize: '1.5em' }}
                    icon={props.icon}
                />
            </NavLink>
        </li>
    )
}

export default NavBarItem
