import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import TextareaCountCharField from '../../../../components/Input/TextareaCountCharField'
import InputCountCharField from '../../../../components/Input/InputCountCharField'
import InputPhotoField from '../../../../components/Input/InputPhotoField'
import { officeActions } from '../../../../actions/Website/office.actions'

class OfficeForm extends Component {
    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleSubmit(value) {
        this.props.updateOffice(value, value.id)
    }

    render() {
        return (
            <form id="update_office">
                <div className="padding_form">
                    <label>
                        <b>Titre:</b>
                    </label>

                    <p style={{ color: 'rgb(230, 126, 35)' }}>
                        <b>Attention</b>: Le titre ci-dessous a été rédigé par
                        notre spécialiste. Il est déjà optimisé pour le
                        référencement sur Google. En changeant ce dernier, vous
                        risquez de perdre de la puissance.
                    </p>
                    <div>
                        <Field
                            name="title"
                            component={InputCountCharField}
                            className="fiche_update"
                        />
                    </div>
                </div>
                <div className="padding_form">
                    <label>
                        <b>Description:</b>
                    </label>
                    <p style={{ color: '#33ab9f' }}>
                        Nous vous conseillons un maximum de 800 characters
                    </p>
                    <div>
                        <Field
                            name="description"
                            component={TextareaCountCharField}
                            style={{ height: '200px' }}
                        />
                    </div>
                </div>
                <div className="padding_form">
                    <label>
                        <b>Une petit mot de votre part:</b>
                    </label>

                    <p style={{ color: '#33ab9f' }}>
                        Nous vous conseillons un maximum de 150 characters
                    </p>
                    <div>
                        <Field
                            name="citation"
                            component={TextareaCountCharField}
                            style={{ height: '100px' }}
                        />
                    </div>
                </div>
                <div className="padding_form">
                    <label className="label-file">
                        <b>Photo d'illustration:</b>
                    </label>
                    <Field
                        name="photo"
                        component={InputPhotoField}
                        style={{
                            height: '340px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            display: 'block',
                            paddingTop: '12px',
                        }}
                    />
                </div>
                <div className="text-right mt-3">
                    <button
                        type="button"
                        id="update_office"
                        onClick={this.props.handleSubmit(this.handleSubmit)}
                        className="btn btn-primary"
                    >
                        Enregistrer les modifications
                    </button>
                </div>
            </form>
        )
    }
}

export default reduxForm({ form: 'update_office' })(
    connect(null, { updateOffice: officeActions.updateOffice })(OfficeForm)
)
