import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import HomePageForm from './HomePageForm'
import { homeActions } from '../../../../actions/Website/home.actions.js'
import Loader from '../../../../components/Loader/Loader'

class HomePage extends Component {
    componentDidMount() {
        if (this.props.home.home === null) {
            this.props.getHome(this.props.website.website.id)
        }
    }

    render() {
        const { loading, home } = this.props.home

        return loading ? (
            <Loader />
        ) : (
            <div>
                <HomePageForm initialValues={home} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        home: state.home,
        website: state.website,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getHome: homeActions.getHome }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
