import React, { Component, Fragment } from 'react'
import { articleActions } from '../../../../../actions/Website/article.actions'
import { DateTime } from 'luxon'
import ArticleForm from '../ArticleForm/ArticleForm'
import Modal from '../../../../../components/Modal/Modal'
import { bindActionCreators } from 'redux'
import { modalActions } from '../../../../../actions/modal.actions'
import { connect } from 'react-redux'

class ArticleCard extends Component {
    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
    }

    submit(value) {
        this.props.updateArticle(value, value.id)
        this.props.hideModal('EditArticle' + value.id)
    }

    render() {
        return (
            <div className="card_visio article_card">
                <div
                    style={{
                        height: '100%',
                        width: '80%',
                        display: 'flex',
                        cursor: 'pointer',
                    }}
                >
                    <img src={this.props.article.photo.url} />
                    <div
                        className="card_visio_info"
                        style={{ padding: '26px' }}
                    >
                        <h2>{this.props.article.title}</h2>

                        <p>
                            Créer le{' '}
                            {DateTime.fromISO(this.props.article.created_at)
                                .setLocale('fr')
                                .toFormat('dd LLL yyyy')}
                        </p>
                    </div>
                </div>
                <div className="padding_cta">
                    <Modal
                        id={'EditArticle' + this.props.article.id}
                        btnStyle="btn btn-primary"
                        btnTitle="Editer"
                    >
                        <Fragment>
                            <h2 className="text-center">
                                Editer {this.props.article.title}
                            </h2>
                            <ArticleForm
                                onSubmit={this.submit}
                                initialValues={this.props.article}
                            />
                        </Fragment>
                    </Modal>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateArticle: articleActions.updateArticle,
            hideModal: modalActions.hideModal,
        },
        dispatch
    )
}

export default connect(null, mapDispatchToProps)(ArticleCard)
