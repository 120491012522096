import React, { Component } from 'react'
import Loader from '../../../components/Loader/Loader'
import { Bar } from 'react-chartjs-2'
import { bindActionCreators } from 'redux'
import { chartsActions } from '../../../actions/charts.actions'
import { connect } from 'react-redux'
import { statsHelper } from '../../../helpers/StatsHelper'

class QuoationAccept extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        this.props.getQuotationAccept()
    }

    render() {
        const { quotationAccept, loading } = this.props.quotationAccept
        const label = statsHelper().label
        const corespondance = statsHelper().correspondance

        let dataset = []

        if (quotationAccept !== null) {
            quotationAccept.map(
                (value) => (dataset[corespondance[value[0]]] = value[1])
            )
        }

        const data = {
            labels: label,
            datasets: [
                {
                    label: 'Devis acceptés',
                    backgroundColor: '#1edd98',
                    borderWidth: 1,
                    data: dataset,
                },
            ],
        }
        return loading ? (
            <Loader />
        ) : (
            <div className="containerStats">
                <h3>Devis accepeté par mois</h3>
                <Bar data={data} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        quotationAccept: state.quotationAccept,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getQuotationAccept: chartsActions.getQuotationAccept,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoationAccept)
