import { combineReducers } from 'redux'
import { authentication } from './auth'
import { notairesPremium, notairePremium } from './notairesPremium'
import { lead } from './lead'
import {
    demandeDevis,
    demandeDevisZone,
    departement,
    departements,
    leadsMonth,
    leadsStatuts,
    leadsZone,
    notaireMap,
    quotationAccept,
    quotationConversionRate,
    turnover,
    usersConnection,
} from './charts'
import { statsUsers } from './UserStatistiques/userStatistiques'
import { userLeads } from './UserStatistiques/userLeads'
import { userNotes } from './UserStatistiques/userNotes'
import { userProfilStats } from './UserStatistiques/userProfilStats'
import { reducer as formReducer } from 'redux-form'
import { userCities } from './UserStatistiques/userCities'
import { website, websites } from './Website/website'
import { home } from './Website/home'
import { office } from './Website/office'
import { equipe } from './Website/equipe'
import { teammate } from './Website/teammate'
import { article, articles } from './Website/article'
import { footer } from './Website/footer'
import { fiche, horraires } from './Fiche/notaire'
import { categories } from './Fiche/categories'
import { modal } from './modal'
import { contact } from './contact'

export const reducers = combineReducers({
    authentication: authentication,
    statsUsers: statsUsers,
    leadsZone: leadsZone,
    leadsMonth: leadsMonth,
    turnover: turnover,
    quotationConversionRate: quotationConversionRate,
    quotationAccept: quotationAccept,
    departements: departements,
    departement: departement,
    demandeDevis: demandeDevis,
    demandeDevisZone: demandeDevisZone,
    usersConnection: usersConnection,
    notairePremium: notairePremium,
    notairesPremium: notairesPremium,
    userLeads: userLeads,
    userNotes: userNotes,
    userProfilStats: userProfilStats,
    leadsStatuts: leadsStatuts,
    userCities: userCities,
    fiche: fiche,
    categories: categories,
    horraires: horraires,
    leads: lead,
    website: website,
    websites: websites,
    home: home,
    office: office,
    equipe: equipe,
    teammate: teammate,
    article: article,
    articles: articles,
    footer: footer,
    form: formReducer,
    modal: modal,
    contact: contact,
    notaireMap: notaireMap,
})
