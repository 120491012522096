import React from 'react'
import Auth from './containers/Auth/Auth'

function App() {
    return (
        <div className="App">
            <Auth />
        </div>
    )
}

export default App
