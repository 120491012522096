import React from 'react'

const UsersHeader = () => {
    return (
        <div className="header">
            <h2>Liste des clients Notre Notaire</h2>
        </div>
    )
}

export default UsersHeader
