import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class NotreNotaireHeader extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { notairePremium } = this.props.notairePremium

        return (
            <Fragment>
                <div className="header">
                    <h2>
                        {notairePremium.nom} {notairePremium.prenom}
                    </h2>{' '}
                    <br />
                </div>
                <Link to={`/notaire/${notairePremium.id}`}>
                    <p>Lien vers la fiche</p>
                </Link>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        notairePremium: state.notairePremium,
    }
}

export default connect(mapStateToProps, null)(NotreNotaireHeader)
