import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { notairePremiumActions } from '../../../actions/notairePremium.actions'
import { DateTime } from 'luxon'
import Loader from '../../../components/Loader/Loader'

class NotairePremiumNotes extends Component {
    componentDidMount() {
        if (this.props.notairePremium.notairePremium !== null) {
            if (this.props.userNotes.userNotes.length === 0) {
                this.props.getUserNotes(
                    this.props.notairePremium.notairePremium.user_id
                )
            }
        }
    }

    render() {
        const { loading, userNotes } = this.props.userNotes
        return loading ? (
            <Loader />
        ) : (
            <Fragment>
                <h4>
                    <u>Notes: {userNotes.length}</u>
                </h4>
                <div className="bloc_user_notes">
                    <br />
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Moyenne</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userNotes.map((note) => (
                                <tr key={note.id}>
                                    <th scope="col">{note.id}</th>
                                    <th scope="col">
                                        {note.moyenne.moyenne} / 5
                                    </th>
                                    <th scope="col">{note.nom}</th>
                                    <th scope="col">
                                        {DateTime.fromISO(note.created_at)
                                            .setLocale('fr')
                                            .toFormat('dd LLL yyyy')}
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        userNotes: state.userNotes,
        notairePremium: state.notairePremium,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getUserNotes: notairePremiumActions.getUserNotes },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(NotairePremiumNotes)
