import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { notairePremiumActions } from '../../../actions/notairePremium.actions'
import Loader from '../../../components/Loader/Loader'

class NotairePremiumCities extends Component {
    componentDidMount() {
        if (this.props.notairePremium.notairePremium !== null) {
            if (this.props.userCities.userCities.length === 0) {
                this.props.getUserCities(
                    this.props.notairePremium.notairePremium.user_id
                )
            }
        }
    }

    render() {
        const { loading, userCities } = this.props.userCities

        return loading ? (
            <Loader />
        ) : (
            <Fragment>
                <br />
                <h4>
                    <u>Présence villes: TOP {userCities.length}</u>
                </h4>
                <div className="bloc_user_lead">
                    <br />
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Nom</th>
                                <th scope="col">Code Postal</th>
                                <th scope="col">Nombre d'habitant</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userCities.map((city) => (
                                <tr key={city.id}>
                                    <td>{city.nom}</td>
                                    <td>{city.code_postal}</td>
                                    <td>{city.population_2012}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        userCities: state.userCities,
        notairePremium: state.notairePremium,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getUserCities: notairePremiumActions.getUserCities },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotairePremiumCities)
