export const websiteConstants = {
    WEBSITES_LOADING: 'WEBSITES_LOADING',
    GETWEBSITES_SUCCESS: 'GET_WEBSITES_SUCCESS',
    GETWEBSITES_FAILURE: 'GET_WEBSITES_FAILURE',
    WEBSITE_LOADING: 'WEBSITE_LOADING',
    GETWEBSITE_SUCCESS: 'GET_WEBSITE_SUCCESS',
    GETWEBSITE_FAILURE: 'GET_WEBSITE_FAILURE',
    POSTWEBSITE_REQUEST: 'POST_WEBSITE_REQUEST',
    PUTWEBSITE_SUCCESS: 'PUT_WEBSITE_SUCCESS',
    PUTWEBSITE_FAILURE: 'PUT_WEBSITE_FAILURE',
    DESTROY_WEBSITE: 'DESTROY_WEBSITE',
}
