import React, { Component } from 'react'
import WebsiteHeader from './WebsiteHeader'
import WebsiteList from './WebsiteList'

class WebSites extends Component {
    render() {
        return (
            <div className="containerAdmin">
                <WebsiteHeader />
                <hr />
                <WebsiteList />
            </div>
        )
    }
}

export default WebSites
