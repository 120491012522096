import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class WebsiteHeader extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="row mt-4 mb-4">
                <div className="col-6">
                    <h2>Gestion des Website</h2>
                </div>
                <div className="col-6 text-right">
                    <Link to={'websites/add'} className="btn btn-primary">
                        Creation Website
                    </Link>
                </div>
            </div>
        )
    }
}

export default WebsiteHeader
