import React from 'react'
import HorrairesForm from './HorrairesForm/HorrairesForm'
import Modal from '../../../../components/Modal/Modal'

const FicheHorrairesHeader = () => {
    return (
        <div className="text-right mb-5">
            <Modal
                id="NewHoraire"
                btnStyle="btn btn-primary"
                btnTitle="Ajouter un horraire"
            >
                <HorrairesForm />
            </Modal>
        </div>
    )
}

export default FicheHorrairesHeader
