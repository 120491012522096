import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

class LeadTable extends Component {
    render() {
        if (!this.props.data) {
            return <p>Chargement...</p>
        }
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Nom</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col">Categorie</th>
                        <th scope="col">Dpt</th>
                        <th scope="col">Création</th>
                        <th scope="col">Source</th>
                        <th scope="col">Notaire</th>
                        <th scope="col">Premium</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.map((lead) => (
                        <tr key={lead.id}>
                            <td>
                                <Link to={`lead/${lead.id}`}>{lead.nom}</Link>
                            </td>
                            <td>
                                <Link to={`lead/${lead.id}`}>{lead.email}</Link>
                            </td>
                            <td>
                                <Link to={`lead/${lead.id}`}>
                                    {lead.telephone}
                                </Link>
                            </td>
                            <td>
                                <Link to={`lead/${lead.id}`}>
                                    {lead.category.category_name}
                                </Link>
                            </td>
                            <td>
                                <Link to={`lead/${lead.id}`}>
                                    {lead.geo.geo_cp}
                                </Link>
                            </td>
                            <td>
                                <Link to={`lead/${lead.id}`}>
                                    {DateTime.fromISO(lead.created_at)
                                        .setLocale('fr')
                                        .toFormat('dd LLL yyyy')}
                                </Link>
                            </td>
                            <td>
                                <Link to={`lead/${lead.id}`}>
                                    {lead.source}
                                </Link>
                            </td>
                            <td>
                                <Link to={`lead/${lead.id}`}>
                                    {lead.attribution.nom}
                                </Link>
                            </td>
                            <td>
                                <Link to={`lead/${lead.id}`}>
                                    {lead.attribution.dispo ? '✅' : '❌'}
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}

export default LeadTable
