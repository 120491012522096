import React, { Component } from 'react'
import { LeadHeader } from './LeadShow/LeadHeader'
import LeadShow from './LeadShow/LeadShow'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getLead } from '../../actions/leads.actions.js'

class Lead extends Component {
    componentDidMount() {
        if (!this.props.lead) {
            this.props.getLead(this.props.match.params.id)
        }
    }
    render() {
        if (!this.props.lead) {
            return <p>Loading...</p>
        }
        return (
            <div className="containerAdmin">
                <LeadHeader
                    lastname={this.props.lead.nom}
                    firstname={this.props.lead.prenom}
                    created_at={this.props.lead.created_at}
                />
                <hr />
                <LeadShow lead={this.props.lead} />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const id = parseInt(ownProps.match.params.id, 10)
    const lead = state.leads.find((lead) => lead.id === id)
    return { lead }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getLead }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Lead)
