import React, { Component } from 'react'

class Loader extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="text-center ">
                <div className="lds-ring ">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <h2>Chargement...</h2>
            </div>
        )
    }
}

export default Loader
