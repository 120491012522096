import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'

class UserFiltre extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { handleSubmit } = this.props
        return (
            <div className="mb-5">
                <form name="notaire" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-3 ">
                            <div className="form-group">
                                <Field
                                    name="nom"
                                    component="input"
                                    placeholder="Nom"
                                    className=""
                                />
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <div className="form-group">
                                <Field
                                    name="signe"
                                    component="select"
                                    className=""
                                >
                                    <option value=""> -- </option>
                                    <option value="inf"> &#x3C; </option>
                                    <option value="sup"> &#x3E; </option>
                                </Field>
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <div className="form-group">
                                <Field
                                    name="nbLead"
                                    component="input"
                                    type="number"
                                    placeholder="Leads"
                                    className=""
                                />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <Field
                                    name="ville"
                                    component="input"
                                    placeholder="Ville"
                                    className=""
                                />
                            </div>
                        </div>
                        <div className=" col-lg-2">
                            <button className="btn btn-primary" type="submit">
                                Filtrer
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default reduxForm({ form: 'notaire' })(UserFiltre)
