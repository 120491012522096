import { footerConstants } from '../../constants/footer.constants'
import { websiteConstants } from '../../constants/website.constants'

const footerState = {
    loading: true,
    footer: null,
    error: false,
}

export function footer(state = footerState, action) {
    switch (action.type) {
        case footerConstants.FOOTER_LOADING:
            return {
                ...state,
                loading: true,
            }
        case footerConstants.PUTFOOTER_SUCCESS:
        case footerConstants.GETFOOTER_SUCCESS:
            return {
                ...state,
                loading: false,
                footer: action.payload.data,
            }
        case websiteConstants.DESTROY_WEBSITE:
            return footerState
        default:
            return state
    }
}
