import { categoryConstants } from '../../constants/category.constants'

const categoriesState = {
    loading: true,
    categories: [],
    error: false,
}

export function categories(state = categoriesState, action) {
    switch (action.type) {
        case categoryConstants.GETCATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload.data,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}
