import { res } from '../helpers/dispatch'
import { contactServices } from '../services/contact.services'
import { contactConstants } from '../constants/contact.constants'

export const contactActions = {
    getContact,
    updateContact,
    deleteContact,
}

function getContact() {
    return (dispatch) => {
        dispatch(res.loading(contactConstants.GETCONTACT_LOADING))
        contactServices
            .getContact()
            .then((response) => {
                let contact = response.data
                dispatch(
                    res.success(contactConstants.GETCONTACT_SUCCESS, contact)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(contactConstants.GETCONTACT_FAILURE, error)
                )
            })
    }
}

function updateContact(id, data) {
    return (dispatch) => {
        dispatch(res.loading(contactConstants.UPDATECONTACT_LOADING))
        contactServices
            .updateContact(id, data)
            .then((response) => {
                let contact = response.data
                dispatch(
                    res.success(contactConstants.UPDATECONTACT_SUCCESS, contact)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(contactConstants.UPDATECONTACT_FAILURE, error)
                )
            })
    }
}

function deleteContact(id) {
    return (dispatch) => {
        dispatch(res.loading(contactConstants.DELETECONTACT_LOADING))
        contactServices
            .deleteContact(id)
            .then(() => {
                dispatch(
                    res.success(contactConstants.DELETECONTACT_SUCCESS, id)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(contactConstants.DELETECONTACT_FAILURE, error)
                )
            })
    }
}
