import React, { Component } from 'react'
import { notaireActions } from '../../../actions/notaire.actions.js'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class PasswordFicheForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault()
        this.props.updatePassword(
            { user: { password: this.state.password } },
            this.props.fiche.fiche.id,
            this.props.fiche.fiche
        )
    }

    handleChange(event) {
        this.setState({ password: event.target.value })
    }

    render() {
        return (
            <div>
                <h4>Actualisation du mot de passe : </h4>
                <form className="mt-4" onSubmit={this.handleSubmit}>
                    <label>Nouveau mot de passe </label>
                    <input
                        type="text"
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                    <div className="text-center mt-4">
                        <button className="btn btn-primary" type="submit">
                            Enregistrer les modifications
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { fiche: state.fiche }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { updatePassword: notaireActions.updatePassword },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordFicheForm)
