import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { statsUsersActions } from '../../../actions/userstatistiques.actions.js'
import { connect } from 'react-redux'
import Loader from '../../../components/Loader/Loader'

class UserStatistiques extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getUserStatistiques()
    }

    render() {
        const { statsUsers, loading } = this.props.statsUsers

        return loading ? (
            <Loader />
        ) : (
            <div className="bloc-statistique">
                <h4>Statistiques</h4>
                <hr />
                <ul>
                    <li>
                        Nbr lead total{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.nombre_lead}
                        </b>
                    </li>
                    <li>
                        Nbr de leads attribués:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.nombre_leads_attribue}
                        </b>
                    </li>
                    <li>
                        % leads attribués:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.percent_lead_attribued}%
                        </b>
                    </li>
                    <li>
                        Nbr de clients:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.nombre_clients}
                        </b>
                    </li>
                    <li>
                        Moyenne leads/clients:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.moyenne_client}
                        </b>
                    </li>
                </ul>
                <hr />
                <h4>Sources:</h4>
                <ul>
                    <li>
                        lead PAP:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.pap}
                        </b>
                    </li>
                    <li>
                        lead Edito Liste:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.edito}
                        </b>
                    </li>
                    <li>
                        Lead Edito Détail:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.edito_show}
                        </b>
                    </li>
                    <li>
                        lead Fiches Liste:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.liste_fiches}
                        </b>
                    </li>
                    <li>
                        lead Fiche détail:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.fiche_notaire}
                        </b>
                    </li>
                    <li>
                        lead Home Page:{' '}
                        <b style={{ fontSize: '14px', color: '#2287ff' }}>
                            {statsUsers.home_page}
                        </b>
                    </li>
                </ul>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { statsUsers: state.statsUsers }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getUserStatistiques: statsUsersActions.getUserStatistiques },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserStatistiques)
