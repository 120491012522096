import React, { Component, Fragment } from 'react'
import { categoriesActions } from '../../../actions/categories.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { notaireActions } from '../../../actions/notaire.actions.js'
import Loader from '../../../components/Loader/Loader'
import { Fields, reduxForm } from 'redux-form'
import InputCatField from '../../../components/Input/InputCatField'

class SpecialityForm extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.categories.categories.length === 0) {
            this.props.getCategories()
        }
    }

    handleSubmit = (value) => {
        let data = {
            category_ids: value.category_ids,
            category_ids_forward: value.category_ids_forward,
        }
        this.props.updateFiche(data, value.id, value)
    }

    render() {
        const { loading, categories } = this.props.categories

        return loading ? (
            <Loader />
        ) : (
            <Fragment>
                <form name="speciality_form">
                    <div className="padding_form">
                        <Fields
                            component={InputCatField}
                            names={[
                                'categories',
                                'category_ids',
                                'category_ids_forward',
                            ]}
                            allCategories={categories}
                        />

                        <div className="text-right">
                            <button
                                onClick={this.props.handleSubmit(
                                    this.handleSubmit
                                )}
                                type="button"
                                id="speciality_form"
                                className="btn btn-primary"
                            >
                                Enregistrer les modifications
                            </button>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        fiche: state.fiche,
        categories: state.categories,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCategories: categoriesActions.getCategories,
            updateFiche: notaireActions.updateFiche,
        },
        dispatch
    )
}

export default reduxForm({ form: 'speciality_form' })(
    connect(mapStateToProps, mapDispatchToProps)(SpecialityForm)
)
