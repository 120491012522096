export const chartsConstants = {
    GETLEADSZONE_LOADING: 'GET_LEADS_ZONE_LOADING',
    GETLEADSZONE_SUCCESS: 'GET_LEADS_ZONE_SUCCESS',
    GETLEADSZONE_FAILURE: 'GET_LEADS_ZONE_FAILURE',
    GETLEADSMONTH_LOADING: 'GET_LEADS_MONTH_LOADING',
    GETLEADSMONTH_SUCCESS: 'GET_LEADS_MONTH_SUCCESS',
    GETLEADSMONTH_FAILURE: 'GET_LEADS_MONTH_FAILURE',
    GETLEADSTATUT_LOADING: 'GET_LEADS_STATUTS_LOADING',
    GETLEADSTATUT_SUCCESS: 'GET_LEADS_STATUTS_SUCCES',
    GETLEADSTATUT_FAILURE: 'GET_LEADS_STATUTS_FAILURE',
    GETTURNOVER_LOADING: 'GET_TURNOVER_LOADING',
    GETTURNOVER_SUCCESS: 'GET_TURNOVER_SUCCESS',
    GETTURNOVER_FAILURE: 'GET_TURNOVER_FAILURE',
    GETDEMANDEDEVIS_LOADING: 'GET_DEMANDEDEVIS_LOADING',
    GETDEMANDEDEVIS_SUCCESS: 'GET_DEMANDEDEVIS_SUCCESS',
    GETDEMANDEDEVIS_FAILURE: 'GET_DEMANDEDEVIS_FAILURE',
    GETDEMANDEDEVISZONES_LOADING: 'GET_DEMANDEDEVISZONES_LOADING',
    GETDEMANDEDEVISZONES_SUCCESS: 'GET_DEMANDEDEVISZONES_SUCCESS',
    GETDEMANDEDEVISZONES_FAILURE: 'GET_DEMANDEDEVISZONES_FAILURE',
    GETUSERCONNECTIONS_LOADING: 'GET_USER_CONNECTIONS_LOADING',
    GETUSERCONNECTIONS_SUCCESS: 'GET_USER_CONNECTIONS_SUCCESS',
    GETUSERCONNECTIONS_FAILURE: 'GET_USER_CONNECTIONS_FAILURE',
    GETQUOTATIONCONVERSIONRATE_LOADING: 'GET_QUOTATION_CONVERSION_RATE_LOADING',
    GETQUOTATIONCONVERSIONRATE_SUCCESS: 'GET_QUOTATION_CONVERSION_RATE_SUCCESS',
    GETQUOTATIONCONVERSIONRATE_FAILURE: 'GET_QUOTATION_CONVERSION_RATE_FAILURE',
    GETQUOTATIONACCEPT_LOADING: 'GET_QUOTATION_ACCEPT_LOADING',
    GETQUOTATIONACCEPT_SUCCESS: 'GET_QUOTATION_ACCEPT_SUCCESS',
    GETQUOTATIONACCEPT_FAILURE: 'GET_QUOTATION_ACCEPT_FAILURE',
    GETDEPARTEMENTSSTAT_LOADING: 'GET_DEPARTEMENTS_STAT_LOADING',
    GETDEPARTEMENTSSTAT_SUCCESS: 'GET_DEPARTEMENTS_STAT_SUCCESS',
    GETDEPARTEMENTSSTAT_FAILURE: 'GET_DEPARTEMENTS_STAT_FAILURE',
    GETDEPARTEMENTSTAT_LOADING: 'GET_DEPARTEMENT_STAT_LOADING',
    GETDEPARTEMENTSTAT_SUCCESS: 'GET_DEPARTEMENT_STAT_SUCCESS',
    GETDEPARTEMENTSTAT_FAILURE: 'GET_DEPARTEMENT_STAT_FAILURE',
    GETNOTAIREMAP_LOADING: 'GET_NOTAIRE_MAP_LOADING',
    GETNOTAIREMAP_SUCCESS: 'GET_NOTAIRE_MAP_SUCCESS',
    GETNOTAIREMAP_FAILURE: 'GET_NOTAIRE_MAP_FAILURE',
}
