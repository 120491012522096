export const contactConstants = {
    GETCONTACT_LOADING: 'GET_CONTACT_LOADING',
    GETCONTACT_SUCCESS: 'GET_CONTACT_SUCCESS',
    GETCONTACT_FAILURE: 'GET_CONTACT_FAILURE',
    UPDATECONTACT_LOADING: 'UPDATE_CONTACT_LOADING',
    UPDATECONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
    UPDATECONTACT_FAILURE: 'UPDATE_CONTACT_FAILURE',
    DELETECONTACT_LOADING: 'DELETE_CONTACT_LOADING',
    DELETECONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
    DELETECONTACT_FAILURE: 'DELETE_CONTACT_FAILURE',
}
