import React, { Component } from 'react'
import { articleActions } from '../../../../../actions/Website/article.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ArticleCard from './ArticleCard'
import Loader from '../../../../../components/Loader/Loader'

class ArticlesList extends Component {
    componentDidMount() {
        if (this.props.articles.articles.length === 0) {
            this.props.getArticles(this.props.website.website.id)
        }
    }

    render() {
        const { loading, articles } = this.props.articles

        return loading ? (
            <Loader />
        ) : (
            <div className="mb-5">
                {articles.map((article) => (
                    <ArticleCard key={article.id} article={article} />
                ))}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        articles: state.articles,
        website: state.website,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getArticles: articleActions.getArticles },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesList)
