import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { chartsActions } from '../../../actions/charts.actions.js'
import { Pie } from 'react-chartjs-2'
import Loader from '../../../components/Loader/Loader'

class LeadStatut extends Component {
    componentDidMount() {
        if (this.props.leadsStatuts.leadsStatuts.length === 0) {
            this.props.getLeadsStatuts()
        }
    }

    render() {
        const { leadsStatuts, loading } = this.props.leadsStatuts
        const label = []
        const dataset = []

        leadsStatuts.map(
            (value) => label.push(value[0]) && dataset.push(value[1])
        )

        const data = {
            labels: label,
            datasets: [
                {
                    data: dataset,
                    backgroundColor: [
                        '#19D891',
                        '#FF8C8C',
                        '#8A8CFF',
                        '#66adff',
                    ],
                    hoverBackgroundColor: [
                        '#19D891',
                        '#FF8C8C',
                        '#8A8CFF',
                        '#66adff',
                    ],
                },
            ],
        }

        return loading ? (
            <Loader />
        ) : (
            <div className="containerStats">
                <h3>Statuts des leads envoyés</h3>
                <Pie data={data} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        leadsStatuts: state.leadsStatuts,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getLeadsStatuts: chartsActions.getLeadsStatuts },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadStatut)
