import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { notaireActions } from '../../../../../actions/notaire.actions.js'
import { modalActions } from '../../../../../actions/modal.actions'

class HorrairesForm extends Component {
    constructor(props) {
        super(props)

        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(values) {
        this.props.createHorraire(values, this.props.fiche.fiche.id)
        this.props.hideModal('NewHoraire')
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <h2>Créer un horaire</h2>
                <div>
                    <label>Jour d'ouverture:</label>
                    <Field name="jour" component="select">
                        <option />
                        <option value="1">Lundi</option>
                        <option value="2">Mardi</option>
                        <option value="3">Mercredi</option>
                        <option value="4">Jeudi</option>
                        <option value="5">Vendredi</option>
                        <option value="6">Samedi</option>
                        <option value="7">Dimanche</option>
                    </Field>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Horraire d'ouverture le matin:</label>
                        <Field
                            name="open_morning"
                            type="time"
                            component="input"
                        />
                        <label>Horraire d'ouverture l'après midi:</label>
                        <Field
                            name="open_afternoon"
                            type="time"
                            component="input"
                        />
                    </div>
                    <div className="col">
                        <label>Horraire de fermeture le matin:</label>
                        <Field
                            name="close_morning"
                            type="time"
                            component="input"
                        />
                        <label>Horraire de fermeture l'après midi:</label>
                        <Field
                            name="close_afternon"
                            type="time"
                            component="input"
                        />
                    </div>
                </div>
                <div>
                    <br />
                    <button
                        className="btn btn-default"
                        type="submit"
                        disabled={this.props.pristine || this.props.submitting}
                    >
                        Créer l'horaire
                    </button>
                </div>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        fiche: state.fiche,
    }
}

export default reduxForm({ form: 'horraire' })(
    connect(mapStateToProps, {
        createHorraire: notaireActions.createHorraire,
        hideModal: modalActions.hideModal(),
    })(HorrairesForm)
)
