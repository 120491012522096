import { websiteConstants } from '../../constants/website.constants'

const websitesState = {
    loading: true,
    websites: [],
    error: false,
}

export function websites(state = websitesState, action) {
    switch (action.type) {
        case websiteConstants.WEBSITES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case websiteConstants.GETWEBSITES_SUCCESS:
            return {
                ...state,
                websites: action.payload.data,
                loading: false,
            }
        default:
            return state
    }
}

const websiteState = {
    loading: true,
    website: null,
    error: false,
}

export function website(state = websiteState, action) {
    switch (action.type) {
        case websiteConstants.WEBSITE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case websiteConstants.PUTWEBSITE_SUCCESS:
        case websiteConstants.GETWEBSITE_SUCCESS:
            return {
                ...state,
                website: action.payload.data,
                loading: false,
            }
        case websiteConstants.DESTROY_WEBSITE:
            return websitesState
        default:
            return state
    }
}
