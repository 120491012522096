import React, { Component } from 'react'
import Loader from '../../../components/Loader/Loader'
import { Bar } from 'react-chartjs-2'
import { bindActionCreators } from 'redux'
import { chartsActions } from '../../../actions/charts.actions'
import { connect } from 'react-redux'
import { statsHelper } from '../../../helpers/StatsHelper'

class RequestQuotation extends Component {
    componentDidMount() {
        this.props.getDemandeDevis()
    }

    render() {
        const { demandeDevis, loading } = this.props.demandeDevis
        const label = statsHelper().label
        const corespondance = statsHelper().correspondance
        let dataPacs, dataMariage, dataSci, dataImmo, dataTestament
        dataPacs = []
        dataMariage = []
        dataSci = []
        dataImmo = []
        dataTestament = []
        if (demandeDevis !== null) {
            demandeDevis.pacs.map(
                (value) => (dataPacs[corespondance[value[0]]] = value[1])
            )
            demandeDevis.mariage.map(
                (value) => (dataMariage[corespondance[value[0]]] = value[1])
            )
            demandeDevis.sci.map(
                (value) => (dataSci[corespondance[value[0]]] = value[1])
            )
            demandeDevis.immo.map(
                (value) => (dataImmo[corespondance[value[0]]] = value[1])
            )
            demandeDevis.testament.map(
                (value) => (dataTestament[corespondance[value[0]]] = value[1])
            )
        }

        const options = {
            scales: {
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
        }

        const data = {
            labels: label,
            datasets: [
                {
                    label: 'Pacs',
                    backgroundColor: '#2287ff',
                    data: dataPacs,
                },
                {
                    label: 'Mariage',
                    backgroundColor: '#FF863B',
                    data: dataMariage,
                },
                {
                    label: 'Immobilier',
                    backgroundColor: '#FFD908',
                    data: dataImmo,
                },
                {
                    label: 'Testament',
                    backgroundColor: '#14F9FF',
                    data: dataTestament,
                },
                {
                    label: 'SCI',
                    backgroundColor: '#3E2EFF',
                    data: dataSci,
                },
            ],
        }
        return loading ? (
            <Loader />
        ) : (
            <div className="containerStats">
                <h3>Nombre de demande de devis par mois</h3>
                <Bar data={data} options={options} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        demandeDevis: state.demandeDevis,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getDemandeDevis: chartsActions.getDemandeDevis },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestQuotation)
