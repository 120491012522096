import { authHeader } from '../helpers/auth-header'

export function fetchAPI(url, method, body, callback) {
    const good_url = `${process.env.API_URL}/${url}`
    const requestOptions = {
        method: method.toUpperCase(),
        headers: authHeader(),
        body: JSON.stringify(body),
    }
    const promise = fetch(good_url, requestOptions)
        .then((response) => {
            localStorage.setItem(
                'pagination_leads',
                JSON.stringify(response.headers.get('Total-Pages'))
            )
            return response
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    logout()
                    location.reload(true)
                }
                const error = response.statusText
                return Promise.reject(error)
            }
            return response.json()
        })
        .then(callback)
    return promise
}

function logout() {
    localStorage.removeItem('user')
}
